

.aboutUsFirstContainerStartHere {
  background: rgba(0, 108, 162, 0.8);
  height: 401px;
}

.aboutUsHeadingAndPara .aboutUsBackgroundImage {
  background-image: url("../Image/About-Us-Page/1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 851px;
  height: 501px;
  margin-top: 3%;
}

.aboutUsBackgroundImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.aboutUsBackgroundImage .aboutUsBackgroundImagePara {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 15%;
  font-style: normal;
  font-weight: 700;
  font-size: 36px !important;
  line-height: 52px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

.below-heading-line span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  width: 118px;
  margin: 0 auto;
  height: 2px;
  margin-top: -2%;
  margin-bottom: 1%;
}

.ourMissionSectionStartHere {
  padding: 2% 0 5% 0;
}

.ourMissionDiv h2 {
  padding: 2% 0;
  margin: 33% 0 3% 0;
}

.ourMissionDiv p {
  padding: 0 17%;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 29px;
  color: #006ca2;
  text-align: justify;
}

.whyChooseUsSectionStartHere {
  padding: 0 15%;
}

.aboutUsHeadingAndPara h2 {
  margin: 3.5% 0 2% 0;
  color: #fff !important;
}

.aboutUsHeadingAndPara p {
  padding: 0 27%;
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
}

.whyChooseUsHeading h2,
.ourMissionDiv h2,
.ourServicesInnerDiv h2,
.ourPerformanceInnerDiv h2,
.haveAnyQuestionsHeading h2,
.aboutUsHeadingAndPara h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #006ca2;
}

.whyChooseUsHeading h2,
.ourPerformanceInnerDiv h2 {
  margin-bottom: 3.5%;
}

.ourServicesInnerDiv h2 {
  margin: 4% 0;
}

.haveAnyQuestionsHeading h2 {
  margin: 5% 0;
}

.whyChooseUsCardUnderAboutUs {
  padding: 2.5%;
}

.whyChooseUsCardUnderAboutUs img {
  border: 1px solid rgba(0, 108, 162, 0.4);
  border-bottom: none;
}

.aboutUsCardBody {
  border-top: none;
  border-right: 1px solid rgba(0, 108, 162, 0.4);
  border-bottom: 1px solid rgba(0, 108, 162, 0.4);
  border-left: 1px solid rgba(0, 108, 162, 0.4);
  padding: 5%;
}

.whyChooseUsCardUnderAboutUs h5 {
  color: #006ca2;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.08em;
}

.whyChooseUsCardUnderAboutUs p {
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 29px;
  color: #006ca2;
  text-align: justify;
}

.whyChooseUsCardNumberFourForCenter {
  position: relative;
}

.whyChooseUsCardNumberFiveForCenter {
  position: relative;
}

.cardImage {
  height: 37vh !important;
}

#aboutUsQualityWork {
  height: 237px;
}

#aboutUsSupport {
  height: 235px;
}

#aboutUsTransparency {
  height: 237px;
}

.ourServicesRightUnOrderedListDissertationChapters {
  padding: 5%;
}

.ourServicesRightUnOrderedListDissertationChapters ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 37px;
  list-style-type: disc;
  color: #ffe02b;
}

.ourServicesRightUnOrderedListDissertationChapters ul li a {
  text-decoration: none;
  color: #fff;
}

.ourServicesLeftButtonOfDissertationServices {
  background: linear-gradient(180deg, rgba(0, 108, 162, 0.28) 0%, rgba(217, 217, 217, 0) 100%);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.ourServicesLeftButtonOfDissertationServices p {
  font-weight: 500;
  font-size: 19px;
  line-height: 35px;
  letter-spacing: 0.07em;
  padding: 15px 55px;
}

.ourServicesLeftButtonOfDissertationServices p a {
  text-decoration: none;
  color: #006ca2;
}

.ourServicesLeftButtonOfDissertationServices p .ourServicesChevronRightButton {
  position: relative;
  float: right;
}

.ourServicesLeftButtonOfDissertationServices p .ourServicesChevronRightButton i {
  font-weight: 900px;
}

/* Carousel Section Start Here  */

.carousel-control-prev-icon,
.carousel-control-next-icon {
  font-size: 35px;
  color: #006ca2;
  font-weight: 700;
}

.reviewsContainerStartHere {
  margin: 3% 0 3% 0;
  background-color: #fff !important;
}

.carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.aboutUsPageInnerPartOfCarousel {
  background-color: #ffffff !important;
}

.dissertationAboutUsCarouselItems {
  height: 39vh;
  min-height: 249px;
  background: no-repeat scroll center scroll;
  -webkit-background-size: cover;
  background-size: cover;
}

.dissertationAboutUsCarouselItems p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #006ca2 !important;
}

.carousel-caption {
  bottom: 31px;
  padding-left: 100px;
  padding-right: 100px;
}

.aboutUsPageMainCarousel button {
  color: #006ca2 !important;
}

.below-heading-line-carousel span {
  background: #ffe02b;
  width: 302px;
  height: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 3%;
}

.ourPerformanceSectionStartHere {
  padding: 1% 0 3% 0;
  margin: 1% 0 3% 0;
}

.ourPerformanceSmallSection .ourPerformanceData {
  width: 121px;
  height: 121px;
  border: 7px solid #006ca2;
  border-radius: 50%;
}

.ourPerformanceSmallSection .ourPerformanceData span {
  position: relative;
  left: 10%;
  top: 31%;
  font-size: 27px;
  color: #006ca2;
  font-weight: 700;
}

.ourPerformanceSmallSection .ourPerformanceData .ourPerformanceDataNumberOne {
  position: relative;
  left: 25%;
}

.ourPerformanceSmallSection .ourPerformancePara {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #006ca2;
}

.ourPerformanceSmallSection {
  width: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.haveAnyQuestionsFaq h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
  color: #006ca2;
}

.haveAnyQuestionsFaq p {
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 29px;
  color: #006ca2;
  text-align: justify;
}

.dissertationIntroFaqSection p span {
  color: red;
}

.haveAnyQuestionsFaqIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.haveAnyQuestionsFaqIcon p {
  width: 40px;
  height: 40px;
  border: 2px solid rgba(0, 108, 162, 0.5);
  border-radius: 50%;
  box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
  margin: 0 0 3% 0;
  cursor: pointer;
}

.haveAnyQuestionsFaqIcon p i {
  font-size: 21px;
  text-align: center;
  color: #006ca2 !important;
}

/* COST OF DISSERTATION SECTION  */

.costOfDissertationBackgroundImageSection {
  padding: 4%;
}

.costOfDissertationBackgroundImageSection {
  background-color: #e3f1f7 !important;
}

.costOfDissertationCalculationPart {
  padding: 3.5%;
  width: 500px;
  background: linear-gradient(
    112.98deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
}

.costOfDissertationCalculationPartDiv p,
.costOfDissertationQuickQueryFormPart p,
.costOfDissertationCalculationPartDiv span {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #006ca2;
}

.costOfDissertationCalculationPartButton,
.costOfDissertationQuickQueryFormPartButton {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  background-color: rgba(0, 108, 162, 0.8);
  border: none;
  padding: 2% 5%;
  border-radius: 3px;
}

.costOfDissertationCalculationPartDiv .wordsOrPages {
  width: 250px;
  outline: none;
  border: 0.5px solid #006ca2;
  border-radius: 5px;
  padding: 1.5%;
  font-size: 17px;
}

.costOfDissertationCalculationPartDiv input[type="radio"] {
  color: #006ca2 !important;
  cursor: pointer;
}

.costOfDissertationCalculationPartDiv label,
.costOfDissertationQuickQueryFormPart label {
  color: #006ca2;
}

.costOfDissertationCalculationPartDiv .dissertationPrice {
  font-size: 17px !important;
  border: none;
  outline: none;
  border-bottom: 1px solid #006ca2;
  width: 200px;
}

.costOfDissertationQuickQueryFormPart {
  padding: 3% 4%;
  background: linear-gradient(
    112.98deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.costOfDissertationQuickQueryFormPart label span {
  color: red;
}

.costOfDissertationQuickQueryFormPartDiv input {
  width: 250px;
  outline: none;
  border: 0.5px solid #006ca2;
  padding: 1.5%;
  font-size: 17px;
}

@media only screen and (max-width: 1199px) {
  .ourMissionDiv h2 {
    margin-top: 38%;
  }
  .ourPerformanceSmallSection {
    width: 20%;
  }
  .carousel-caption {
    bottom: 33px;
    padding-left: 75px;
    padding-right: 75px;
  }
  #aboutUsQualityWork, #aboutUsSupport, #aboutUsTransparency {
    height: 261px;
}
.ourServicesLeftButtonOfDissertationServices p {
  padding: 15px 23px;
}
}
@media only screen and (max-width: 1115px) {
  .aboutUsBackgroundImage .aboutUsBackgroundImagePara {
    left: 9%;
    font-size: 33px !important;
    line-height: 55px;
}
}
@media only screen and (max-width: 991px) {
  .aboutUsHeadingAndPara .aboutUsBackgroundImage {
    width: 661px;
    height: 451px;
  }
  .aboutUsBackgroundImage .aboutUsBackgroundImagePara {
    font-size: 34px !important;
    line-height: 49px;
  }
  .ourMissionDiv h2 {
    margin-top: 45%;
  }
  .ourMissionDiv p {
    padding: 0 11%;
  }
  .carousel-caption {
    bottom: 31px;
    padding-left: 25px;
    padding-right: 25px;
}
.ourServicesLeftButtonOfDissertationServices {
  padding: 15px;
}
.ourServicesRightUnOrderedListDissertationChapters {
  padding: 5% !important;
  width: -webkit-fill-available;
}
.carousel-item {
  min-height: 275px;
}
.ourPerformanceSmallSection {
  width: 28%;
}
.costOfDissertationQuickQueryFormPartDiv input {
  width: 100%;
}
.below-heading-line-carousel span {
  width: 249px;
}
}
@media only screen and (max-width: 871px) {
  .aboutUsHeadingAndPara p {
    padding: 0 15%;
}
.aboutUsBackgroundImage .aboutUsBackgroundImagePara {
  left: 0%;
  font-size: 31px !important;
}
}
@media only screen and (max-width: 767px) {
  .whyChooseUsCardUnderAboutUs {
    padding: 2.5% 19%;
}
  .carousel-caption {
    bottom: 23px;
    padding-left: 15px;
    padding-right: 15px;
}
.carousel-item p {
  font-size: 17px;
}
  .ourPerformanceSmallSection {
    width: 35%;
  }
  .ourMissionDiv p {
    padding: 0 5%;
}
.ourMissionDiv h2 {
  margin-top: 47%;
}
.aboutUsHeadingAndPara .aboutUsBackgroundImage {
  width: 545px;
  height: 401px;
}
.whyChooseUsHeading h2, .ourMissionDiv h2, .ourServicesInnerDiv h2, .ourPerformanceInnerDiv h2, .haveAnyQuestionsHeading h2, .aboutUsHeadingAndPara h2 {
  font-size: 27px;
}
.below-heading-line-carousel span {
  width: 199px;
}
#aboutUsQualityWork, #aboutUsSupport, #aboutUsTransparency {
  height: 237px;
}
}
@media only screen and (max-width: 573px) {
  .aboutUsHeadingAndPara .aboutUsBackgroundImage {
    width: 100%;
  }
  .ourServicesInnerDiv h2 {
    font-size: 22px;
}
  .ourServicesLeftButtonOfDissertationServices p {
    font-size: 16px;
}
}
@media only screen and (max-width: 517px) {
  .ourMissionDiv h2 {
    margin-top: 55%;
  }
  .ourPerformanceSmallSection {
    width: 41%;
}
}
@media only screen and (max-width: 482px) {
  .carousel-caption {
    bottom: 35px;
}
}
@media only screen and (max-width: 474px) {
  .aboutUsHeadingAndPara p {
    padding: 0 11%;
}
.aboutUsBackgroundImage .aboutUsBackgroundImagePara {
  font-size: 25px !important;
}
.aboutUsHeadingAndPara .aboutUsBackgroundImage {
  height: 301px;
}
.ourMissionDiv h2 {
  margin-top: 37%;
}
  .carousel-caption {
    bottom: 11px;
    padding-left: 1px;
    padding-right: 1px;
}
.ourPerformanceInnerDiv h2, .haveAnyQuestionsHeading h2, .ourMissionDiv h2, .whyChooseUsHeading h2 {
  font-size: 27px;
}
.ourServicesInnerDiv h2 {
  font-size: 21px;
}
.haveAnyQuestionsFaq h5 {
  font-size: 18px;
}
.haveAnyQuestionsFaq p, .ourMissionDiv p, .whyChooseUsCardUnderAboutUs p {
  font-size: 15px;
}
.whyChooseUsCardButton {
  padding: 2% 5%;
}
.whyChooseUsCardButton a {
  font-size: 15px;
}
.ourPerformanceSmallSection .ourPerformancePara {
  font-size: 13px;
}
.ourPerformanceSmallSection .ourPerformanceData {
  width: 101px;
  height: 101px;
}
.ourPerformanceSmallSection .ourPerformanceData span {
  font-size: 21px;
}
}
@media only screen and (max-width: 443px) {
  .ourMissionDiv h2 {
    margin-top: 41%;
  }
  .ourPerformanceSmallSection {
    width: 45%;
  }
}
@media only screen and (max-width: 389px) {
  .ourServicesInnerDiv h2 {
    font-size: 18px;
}
}
@media only screen and (max-width: 375px) {
  .ourMissionDiv h2 {
    margin-top: 41%;
  }
  .carousel-item {
    min-height: 351px;
}
.carousel-caption {
  bottom: 27px;
}
.ourMissionDiv h2 {
  margin-top: 55%;
}
}
@media only screen and (max-width: 343px) {
  .aboutUsHeadingAndPara h2 {
    font-size: 27px;
  }
  .aboutUsHeadingAndPara p {
    font-size: 15px;
    padding: 0 5%;
}
.below-heading-line span {
  width: 95px;
  margin-top: -1%;
  margin-bottom: 3%;
}
.ourMissionDiv h2 {
  margin-top: 41%;
}
  .aboutUsBackgroundImage .aboutUsBackgroundImagePara {
    font-size: 21px !important;
}
.whyChooseUsCardUnderAboutUs {
  padding: 2% 0;
}
.aboutUsHeadingAndPara .aboutUsBackgroundImage {
  height: 249px;
}
}
@media only screen and (max-width: 320px) {
  .aboutUsBackgroundImage .aboutUsBackgroundImagePara {
    font-size: 17px !important;
}
.aboutUsHeadingAndPara .aboutUsBackgroundImage {
  height: 215px;
}
.ourMissionDiv h2 {
  margin-top: 35%;
}
  .haveAnyQuestionsHeading h2, .ourPerformanceInnerDiv h2 {
    font-size: 23px;
  }
  .ourPerformanceSmallSection {
    width: 53%;
}
.carousel-item p {
  font-size: 15px;
}
}