
.faqCompSectionStartHere {
    padding: 4% 11%;
}

.faqSectionFirstInnerDiv {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2% 4%;
}

.faqParaAndHeadingDiv {
    cursor: pointer;
}

.faqSectionFirstInnerDiv h2, .faqParaAndHeadingDiv h5, .faq-section-on-faq-page h2 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    color: #006CA2;
}

.faqSectionFirstInnerDiv h2, .faq-section-on-faq-page h2 {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 27px;
    line-height: 58px;
    letter-spacing: 0.1em;
}

.faq-section-on-faq-page h2 {
    font-size: 30px;
    margin-top: 2rem;
}

.faqParaAndHeadingDiv h5 {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #006CA2;
    text-align: justify !important;
    width: 100%;
}

.faqCompIconNumberOne {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1rem 2rem;
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
}

.faqParaAndHeadingDiv .content {
    background: linear-gradient(2.28deg, #006CA2 -1.04%, #FFFFFF 19.11%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    padding: 0 2rem;
    height: 0px;
    overflow-y: auto;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    text-align: justify;
    scroll-behavior: smooth;
}

.faqParaAndHeadingDiv .content.show {
    height: 11vw;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .faqParaAndHeadingDiv .content.show {
        height: 14vw;
    }
}
@media only screen and (max-width: 1167px) {
    .faqParaAndHeadingDiv .content.show {
        height: 15vw;
    }
}
@media only screen and (max-width: 1055px) {
    .faqParaAndHeadingDiv .content.show {
        height: 17vw;
    }
}
@media only screen and (max-width: 991px) {
    .faqParaAndHeadingDiv .content.show {
        height: 19vw;
    }
}
@media only screen and (max-width: 935px) {
    .faqParaAndHeadingDiv .content.show {
        height: 21vw;
    }
}
@media only screen and (max-width: 850px) {
    .faqParaAndHeadingDiv .content.show {
        height: 25vw;
    }
}
@media only screen and (max-width: 800px) {
    .faqParaAndHeadingDiv .content.show {
        height: 28vw;
    }
}
@media only screen and (max-width: 767px) {
    .faqCompSectionStartHere {
        padding: 4% 4%;
    }
    .faqParaAndHeadingDiv .content.show {
        height: 31vw;
    }
    .faqSectionFirstInnerDiv h2 {
        font-size: 22px;
        line-height: 45px;
    }
    .faqParaAndHeadingDiv h5 {
        font-size: 19px;
    }
}
@media only screen and (max-width: 650px) {
    .faqParaAndHeadingDiv .content.show {
        height: 37vw;
    }
}
@media only screen and (max-width: 600px) {
    .faqParaAndHeadingDiv .content.show {
        height: 41vw;
    }
}
@media only screen and (max-width: 575px) {
    .faqCompSectionStartHere {
        padding: 4% 2%;
    }
    .faqParaAndHeadingDiv .content.show {
        height: auto;
        padding: 0rem 2rem 2.5rem 2rem;
    }
    .faqSectionFirstInnerDiv h2 {
        font-size: 27px;
    }
    .faqParaAndHeadingDiv h5 {
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.01em;
    }
}
@media only screen and (max-width: 425px) {
    .faqSectionFirstInnerDiv h2 {
        font-size: 20px;
    }
}