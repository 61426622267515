
.dissertationCarouselItems {
  width: 100%;
  height: auto;
  min-height: 500px;
  padding: 2rem 0;
  background: no-repeat scroll center scroll;
}

.dissertationMainCarouselHeadingAndFormDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dissertationFirstCarousel {
  background-image: url('../Image/Landing_Page/Image1.webp');
}

.dissertationSecondCarousel {
  background-image: url('../Image/Landing_Page/Image2.webp');
}

.dissertationThirdCarousel {
  background-image: url('../Image/Landing_Page/Image3.webp');
}

.dissertationFirstCarousel, .dissertationSecondCarousel, .dissertationThirdCarousel {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dissertationCarouselContainer {
  padding: 5% 9%;
}

.dissertationCarouselContainer h5 {
  font-style: normal;
  font-weight: 800;
  font-size: 61px;
  line-height: 69px;
  letter-spacing: 0.03em;
  color: #006CA2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dissertationCarouselContainer .phdTutorsHeading {
  color: #FFFFFF;
}

.dissertationCarouselContainer .dissertaionCarouselInnerBoxOfHeading {
  width: 361px;
  height: 48px;
  background: linear-gradient(360deg, #FFE02B 0%, rgba(255, 233, 102, 0) 100%);
  border-radius: 0px 0px 10px 10px;
}

.dissertationCarouselQuickQueryForm {
  width: 679px;
  height: auto;
  background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
  /* backdrop-filter: blur(20px); */
  border-radius: 5px;
  padding: 1.5%;
  position: relative;
  left: 0%;
  top: 49%;
}

/* .dissertationCarouselQuickQueryForm .carouselWordsOrPages */

.dissertationCarouselQuickQueryForm .carouselEmail, .dissertationCarouselQuickQueryForm .dissertationCarouselFormSelect {
  border: 2px solid #006CA2;
  border-radius: 4px;
  outline: none !important;
  width: 209px;
  padding: 2px;
  background: transparent;
}

.dissertationCarouselFormSelect {
  color: #006CA2 !important;
  font-weight: 500;
}

/* .dissertationCarouselQuickQueryForm .carouselWordsOrPages::placeholder */

.dissertationCarouselQuickQueryForm .carouselWordsOrPages::placeholder, .dissertationCarouselQuickQueryForm .carouselEmail::placeholder {
  color: rgba(0, 108, 162, 0.49);
  font-weight: 500;
  line-height: 23px;
}

.dissertationCarouselQuickQueryForm .dissertationCarouselFormSelect {
  padding: 2px 0;
}

.dissertationCarouselQuickQueryForm .dissertationCarouselFormSelect option {
  color: #006CA2;
  font-weight: 500;
}

.dissertationCarouselSubmitButtonDiv {
  position: relative;
  top: 21px;
  display: flex;
  justify-content: center;
}

.dissertationCarouselSubmitButtonDiv .dissertationCarouselEnquireNowButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  background-color: #006CA2;
  color: #FFFFFF;
  border: none;
  outline: none;
  padding: 1% 2%;
  border-radius: 4px;
  margin-bottom: 4%;
}

.display {
  display: none;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
  .dissertationCarouselQuickQueryForm {
      top: 25%;
  }
}
@media only screen and (max-width: 1199px) {
  .dissertationCarouselQuickQueryForm {
      top: 35%;
  }
  .dissertationCarouselContainer {
      padding: 3% 9%;
  }
}
@media only screen and (max-width: 1051px) {
  .dissertationBelowNavbar a img {
    width: 251px;
    height: 99px;
    margin-top: 0px;
}
}
@media only screen and (max-width: 1020px) {
  .dissertationUpperNavbar .dissertationUpperNavbarUnorderedList .dissertationUpperNavbarListItem {
    margin: 0 31px;
}
  .dissertationBelowNavbar a img {
    width: 206px;
    height: 89px;
    margin-top: -13px;
}
}
@media only screen and (max-width: 991px) {
  .dissertationCarouselQuickQueryForm {
      top: 49%;
  }
}
@media only screen and (max-width: 767px) {
  .dissertationCarouselContainer h5 {
    font-size: 48px;
  }
  .dissertationCarouselContainer .dissertaionCarouselInnerBoxOfHeading {
    width: 271px;
  }
  .dissertationCarouselQuickQueryForm {
    width: 525px;
    top: 9%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 0;
  }
  .carouselEmail.ms-2 {
    margin-left: 0rem !important;
  }
  .dissertationCarouselQuickQueryForm .carouselWordsOrPages, .dissertationCarouselQuickQueryForm .carouselEmail, .dissertationCarouselQuickQueryForm .dissertationCarouselFormSelect {
    width: 90%;
    margin: 6px 0;
}
.dissertationCarouselSubmitButtonDiv {
  top: 14px;
}
.dissertationCarouselSubmitButtonDiv .dissertationCarouselEnquireNowButton {
  font-size: 16px;
  line-height: 23px;
  padding: 3% 0%;
  margin-bottom: 17%;
  width: 160px;
}
.dissertationCarouselSubmitButtonDiv {
margin-top: 5px;
}
}
@media only screen and (max-width: 575px) {
  .dissertationCarouselQuickQueryForm {
    left: 0%;
    width: 411px;
}
}
@media only screen and (max-width: 553px) {
  .dissertationCarouselContainer h5 {
    font-size: 45px;
}
.dissertationCarouselContainer .dissertaionCarouselInnerBoxOfHeading {
  height: 34px;
}
}
@media only screen and (max-width: 425px) {
  .dissertationCarouselQuickQueryForm {
    width: 350px;
}
  .dissertationCarouselContainer .dissertaionCarouselInnerBoxOfHeading {
      width: 225px;
  }
  .dissertationCarouselContainer h5 {
      font-size: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .dissertationCarouselQuickQueryForm {
    width: 315px;
}
}
@media only screen and (max-width: 320px) {
  .dissertationCarouselQuickQueryForm {
    width: auto;
}
}