
.faqSectionStartHere {
    padding: 4% 11%;
}

.faqSectionFirstInnerDiv {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 2% 4%;
}

.faqIconsParaAndHeadingDiv {
    cursor: pointer;
}

.faqSectionFirstInnerDiv h2, .faqIconsParaAndHeadingDiv h5 {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    color: #006CA2;
}

.faqSectionFirstInnerDiv h2 {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 27px;
    line-height: 58px;
    letter-spacing: 0.1em;
}

.faqIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.faqIcons img {
    width: 43px;
    height: 43px;
}

.faqIconsParaAndHeadingDiv h5 {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #006CA2;
    padding: 0 5%;
    margin-top: 7%;
    
}

.faqIconsParaAndHeadingDiv .content {
    background: linear-gradient(2.28deg, #006CA2 -1.04%, #FFFFFF 19.11%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    padding: 0 7%;
    height: 0px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
    text-align: justify;
}

.faqIconsParaAndHeadingDiv .content.show {
    height: 301px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .faqIconsParaAndHeadingDiv .content.show {
        height: 345px;
    }
}
@media only screen and (max-width: 1167px) {
    .faqIconsParaAndHeadingDiv .content.show {
        height: 351px;
    }
}
@media only screen and (max-width: 1055px) {
    .faqIconsParaAndHeadingDiv .content.show {
        height: 421px;
    }
}
@media only screen and (max-width: 991px) {
    .faqIconsParaAndHeadingDiv .content.show {
        height: 361px;
    }
}
@media only screen and (max-width: 935px) {
    .faqIconsParaAndHeadingDiv .content.show {
        height: 391px;
    }
}
@media only screen and (max-width: 767px) {
    .faqSectionStartHere {
        padding: 4% 4%;
    }
    .faqIconsParaAndHeadingDiv .content.show {
        height: 401px;
    }
    .faqSectionFirstInnerDiv h2 {
        font-size: 22px;
        line-height: 45px;
    }
}
@media only screen and (max-width: 650px) {
    .faqIconsParaAndHeadingDiv .content.show {
        height: 451px;
    }
}
@media only screen and (max-width: 575px) {
    .faqSectionStartHere {
        padding: 4% 2%;
    }
    .faqIconsParaAndHeadingDiv .content.show {
        height: auto;
        padding: 2.5rem 2rem;
    }
    .faqSectionFirstInnerDiv h2 {
        font-size: 27px;
    }
}
@media only screen and (max-width: 425px) {
    .faqSectionFirstInnerDiv h2 {
        font-size: 20px;
    }
}