

.bannerSectionStartHere {
    background-image: url('../Image/FAQ_Page/faq-banner-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 451px;
    margin-bottom: 5%;
}

.bannerSectionHeading p {
    position: absolute;
    left: 5%;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 69px;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 0.1em;
    color: #fff;
}

.faqPageSectionStartHere {
    background: #e3f1f7;
    padding: 3%;
}

.faqPageFirstInnerSectionStartHere {
    padding: 3%;
    background: #fff;
}

.faqPageHeading h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 58px;
  text-align: left;
  letter-spacing: 0.1em;
  color: #006ca2;
  margin: 0 0 2% 0;
}

.faqPageHeading p {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 35px;
    text-align: left;
    color: rgba(0, 108, 162, 0.71);
    margin-bottom: 2%;
}

hr {
   color: rgba(0, 108, 162, 0.69) !important;
   width: 1054px;
    height: 10px;
}

.forward-backward-icons-div {
    display: none;
}

.faqPageFaqSection {
    padding: 2% 3%;
}

.needHelpSectionStartHere {
    padding: 3.5% 0;
}

.needHelpFirstHeading h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #006ca2;
    text-align: center;
    margin-bottom: 1.5%;
}

.needHelpSecondHeading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.needHelpSecondHeading .needHelpSecondHeadingSpan {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    color: #006ca2;
    text-align: center;
    padding: 0.5% 10%;
    border: 3px solid #337CB5;
    border-radius: 4px;
}

.needHelpSecondHeading .needHelpSecondHeadingSpan a {
    text-decoration: none;
    color: #006ca2;
}

/* New FAQ Design CSS Start Here */
.faq-page-heading-div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
}

.faq-page-heading-div h5 {
    color: #006ca2;
    font-weight: 600;
    line-height: 31px;
    display: inline-block;
    cursor: pointer;
}

.faq-div h3 {
    color: #006ca2;
    line-height: 33px;
    font-weight: 600;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 108, 162, 0.69);
}

/* Responsive Page Start Here */

@media only screen and (max-width: 807px) {
    .bannerSectionStartHere {
        height: 411px;
    }
}
@media only screen and (max-width: 767px) {
    .bannerSectionHeading p {
        font-size: 31px;
    }
    /* .faq-page-heading-div {
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
    } */
    /* .faq-page-heading-div h5 {
        display: inline-block;
        font-size: 19px;
        text-align: center;
    } */
    .faq-page-heading-div h5 {
        font-size: 17px;
        display: inline-block;
        margin: 0 1rem;
    }
    .faq-page-heading-div h5:first-of-type {
        margin-left: 0;
    }
    .faq-page-heading-div {
        width: 87%;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
    }
    .forward-backward-icons-div {
        display: block;
        position: relative;
    }
    .fbIcon {
        color: rgba(0, 108, 162, 0.69);
        box-shadow: 0 4px 13px #00000040;
        font-weight: 700;
        font-size: 17px;
        position: absolute;
        top: 15px;
        width: 31px;
        height: 31px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .backward-icon {
        right: 10px;
    }
    .active-faq-button {
        text-decoration: underline; /* Change color as needed */
        color: #000 !important;
      }
    /* .faq-page-heading-div {
        justify-content: center;
        align-items: baseline;
        gap: 1rem;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;
    } */
    /* .faq-page-heading-div h5 {
        text-decoration: underline;
        font-size: 17px;
        margin-bottom: 0.1rem;
    } */
}
@media only screen and (max-width: 600px) {
    .bannerSectionStartHere {
        height: 349px;
    }
    .faq-page-heading-div {
        width: 83%;
    }

}
@media only screen and (max-width: 573px) {
    .needHelpFirstHeading h5 {
        font-size: 19px;
    }
    .needHelpSecondHeading .needHelpSecondHeadingSpan {
        font-size: 25px;
    }
    .needHelpSecondHeading img {
        width: 30px;
    }
    .bannerSectionHeading p {
        font-size: 29px;
    }
    .faqPageHeading h2 {
        font-size: 27px;
      }
      
      .faqPageHeading p {
          font-size: 17px;
      }
}
@media only screen and (max-width: 500px) {
    .bannerSectionStartHere {
        height: 349px;
    }
    .bannerSectionHeading p {
        font-size: 21px;
        line-height: 43px;
    }
    .faq-page-heading-div {
        width: 80%;
    }
}
@media only screen and (max-width: 425px) {
    .bannerSectionStartHere {
        height: 301px;
    }
    .faq-page-heading-div {
        width: 77%;
    }
    .fbIcon {
        font-size: 12px;
        top: 19px;
        width: 25px;
        height: 25px;
    }
}
@media only screen and (max-width: 375px) {
    .bannerSectionHeading p {
        line-height: 35px;
    }
}
@media only screen and (max-width: 320px) {
    .bannerSectionStartHere {
        height: 275px;
    }
}