
.testimonialCarouselComponent {
    padding: 1% 0;
}

/* .carouselItemOfThisComponent {
    padding: 3%;
} */

.carouselContainer {
    padding: 5% 9%;
}

.carouselParagraph p {
    color: #006CA2;
    line-height: 29px;
    font-weight: 600;
    text-align: justify;
}

.carouselComponentImage img {
    border-radius: 11%;
}

.testimonialIconAndName {
    position: relative;
    top: 19px;
}

.testimonialIconAndName svg {
    color: #FABD3B !important;
    font-weight: 600;
    font-size: 19px;
    
}

.testimonialIconAndName .testimonialComponentName {
    color: #006CA2;
    font-weight: 600;
    line-height: 29px;
}

.carouselControlPreviousButton span i, .carouselControlNextButton span i {
    color: #006CA2;
    font-weight: bolder;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .carouselContainer {
        padding: 5% 2%;
    }
}
@media only screen and (max-width: 1100px) {
    .carouselParagraph p {
        padding: 0 6%;
    }
    .testimonialComponentImageAndPara {
        position: relative;
        left: 4%;
    }
}
@media only screen and (max-width: 991px) {
    .carouselComponentImage, .testimonialIconAndName {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .testimonialIconAndName svg {
        font-size: 15px;
    }
    .testimonialIconAndName .testimonialComponentName {
        line-height: 0px;
        font-size: 15px;
    }
    .carouselParagraph p {
        margin-top: 7%;
        text-align: center;
    }
    .testimonialCarouselComponentImageStarAndName {
        flex-direction: column-reverse;
    }
    .carouselComponentImage img {
        border: none !important;
        border-radius: 0;
        width: 75px;
    }
    .testimonialComponentImageAndPara {
        left: 0%;
    }
}
@media only screen and (max-width: 575px) {
    .carouselParagraph p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 500px) {
    .carousel-item.carouselItemOfThisComponent {
        min-height: 401px !important;
    }
}
@media only screen and (max-width: 425px) {
    .carousel-item.carouselItemOfThisComponent {
        min-height: 381px !important;
    }
}
@media only screen and (max-width: 320px) {
    .carousel-item.carouselItemOfThisComponent {
        min-height: 427px !important;
    }
}