.dissertationCapstoneBannerImage {
  background-image: url("../Image/Dissertation_Capstone/banner_image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 7%;
}

.bannerImageHeadingSectionInCapstone h1,
.bannerImageHeadingSectionInCapstone h3 {
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #006ca2;
}

.bannerImageHeadingSectionInCapstone h1 {
  font-size: 41px;
  line-height: 69px;
}

.below-heading-line-capstone-banner-heading span {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe02b;
  width: 337px;
  height: 3px;
  margin-top: -1.5%;
  margin-bottom: 2%;
}

.bannerImageHeadingSectionInCapstone p {
  font-size: 24px;
  line-height: 35px;
  color: #006ca2;
  padding: 0 27% 0 0;
  text-align: justify;
}

.bannerImageHeadingSectionInCapstone h3 {
  font-size: 32px;
  line-height: 46px;
}

.bannerImageHeadingSectionInCapstone .capstoneWriteMyProjectButton {
  margin-top: 5%;
  background: #ffe02b;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 46px;
  color: #006ca2;
  padding: 0.5% 4%;
}

.capstoneProjectFirstQuestionSection,
.howToWriteCapstoneProjectSection {
  padding: 0 9%;
}

.capstoneProjectLastQuestionAndFaqSection,
.dissertationCapstoneFaqAndGetDissertationHelpNowButtonSection {
  padding: 0 9% !important;
}

.capstoneFirstQuestionHeadingAndPara h2,
.howToWriteCapstoneHeading h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 46px;
  letter-spacing: 0.1em;
  color: #006ca2;
  margin-top: 5%;
}

.capstoneFirstQuestionHeadingAndPara p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 33px;
  margin: 3% 0 1% 0;
  color: #006ca2;
  text-align: justify;
}

.howToWriteCapstonePara table tr th {
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 35px;
  color: #006ca2;
  position: relative;
  top: 25px;
}

.howToWriteCapstonePara table tr td {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  color: #006ca2;
  padding: 5%;
  text-align: justify;
}

.dissertationCapstoneFaqAndHelpNowSection h2,
.capstoneProjectLastQuestion h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 46px;
  letter-spacing: 0.1em;
  color: #006ca2;
}

.capstoneProjectLastQuestion h2 {
  margin: 3% 0 4% 0;
}

.dissertationCapstoneFaqAndHelpNowSection h2 {
  margin: 3% 0 0 0;
}

.dissertationCapstoneFaqAndHelpNowSection,
.capstoneProjectLastQuestion p {
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 33px;
  margin: 2% 0 0% 0;
  color: #006ca2;
  text-align: justify;
}

.dissertationCapstoneFaqAndHelpNowSection
  .capstoneProjectGetDissertationHelpNow {
  margin: 3% 0 11% 0;
  background: linear-gradient(
    180deg,
    #095b84 0%,
    #37a3d9 40.1%,
    #1f5e7d 73.44%,
    #1f5e7d 100%
  );
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 2.5% 7%;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  outline: none;
  border: none;
}

.dissertationCapstoneFaqAndHelpNowSection
  .capstoneProjectGetDissertationHelpNow a {
    text-decoration: none;
    color: #ffffff;
  }

.dissertationCapstoneFaqAndHelpNowSection .capstoneProjectReadMoreOnFaqSection {
  margin: 1% 0;
}

.dissertationCapstoneFaqAndHelpNowSection
  .capstoneProjectReadMoreOnFaqSection
  span {
  border-bottom: 2px solid #ffe02b;
}

.dissertationCapstoneFaqAndHelpNowSection
  .capstoneProjectReadMoreOnFaqSection
  a {
  text-decoration: none;
  color: #006ca2;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  letter-spacing: 0.02em;
}

/* FAQ Section Start Here  */

.accordion {
  --bs-accordion-btn-padding-x: 0.25rem !important;
  --bs-accordion-btn-padding-y: 1rem !important;
}

.accordion-item h2 button {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  color: #3389b5;
}

.accordion-item .accordion-body {
  font-size: 17px;
  color: #3389b5;
  line-height: 31px;
}

.dissertationCapstoneImportantServices {
  padding: 3% 5% 0 9%;
}

.dissertationCapstoneImportantServices ul {
  background: #ffffff;
  border-top: 5px solid #3389b5;
  border-left: 1px solid #3389b5;
  border-bottom: 1px solid #3389b5;
  border-right: 1px solid #3389b5;
  border-radius: 10px 10px;
}

.dissertationCapstoneImportantServices ul li {
  list-style: none;
  margin: 3% 1%;
  padding: 2% 0;
}

.dissertationCapstoneImportantServices ul li span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  color: #3389b5;
}

.dissertationCapstoneImportantServices ul li img {
  width: 15px;
}

.dissertationCapstoneRightSideImageFaqSection {
  width: 375px;
}

.dissertationCapstoneRightSideImageFaqSection img {
  width: 100%;
}

.dissertationCapstoneImportantServices .capstoneImportantServicesImage {
  margin-top: 13%;
}

.capstoneStatisticsInnerDiv h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 58px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #006ca2;
  margin: 5% 0 3% 0;
}

.capstoneStatisticsSmallSection {
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.capstoneStatisticsSmallSection .capstoneStatisticsData {
  width: 121px;
  height: 121px;
  border: 7px solid #006ca2;
  border-radius: 50%;
}

.capstoneStatisticsSmallSection .capstoneStatisticsData span {
  position: relative;
  left: 10%;
  top: 31%;
  font-size: 27px;
  color: #006ca2;
  font-weight: 700;
}

.capstoneStatisticsSmallSection
  .capstoneStatisticsData
  .capstoneStatisticsDataNumberOne {
  position: relative;
  left: 25%;
}

.capstoneStatisticsSmallSection .capstoneStatisticsPara {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  /* text-align: center; */
  color: #006ca2;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
  .capstoneStatisticsSmallSection {
    width: 20%;
  }
  .dissertationCapstoneImportantServices {
    padding: 3% 5% 0 5%;
  }
}
@media only screen and (max-width: 1108px) {
  .howToWriteCapstonePara table tr th {
    top: 11px;
  }
}
@media only screen and (max-width: 1054px) {
  .dissertationCapstoneFaqAndHelpNowSection
    .capstoneProjectGetDissertationHelpNow {
    font-size: 17px;
  }
}
@media only screen and (max-width: 992px) {
  .capstoneProjectLastQuestionAndFaqSection,
  .dissertationCapstoneFaqAndGetDissertationHelpNowButtonSection {
    padding: 0 7%;
  }
  .dissertationCapstoneImportantServices {
    padding: 3% 1% 0 1%;
  }
}
@media only screen and (max-width: 991px) {
  .capstoneProjectFirstQuestionSection,
  .howToWriteCapstoneProjectSection {
    padding: 0 11%;
  }
  .dissertationCapstoneFaqAndHelpNowSection
    .capstoneProjectGetDissertationHelpNow {
    margin: 3% 0 2% 0;
  }
  .capstoneStatisticsSmallSection {
    width: 31%;
  }
  .dissertationCapstoneImportantServices ul {
    width: 351px;
  }
  .dissertationCapstoneImportantServices {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dissertationCapstoneRightSideImageFaqSection
    .capstoneImportantServicesImage {
    margin-top: 5%;
  }
  .dissertationCapstoneFaqAndGetDissertationHelpNowButtonSection
    .dissertationCapstoneRightSideImageFaqSection {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 850px) {
  .bannerImageHeadingSectionInCapstone h1 {
    font-size: 37px;
  }
}
@media only screen and (max-width: 767px) {
  .bannerImageHeadingSectionInCapstone h1 {
    font-size: 31px;
  }
  .below-heading-line-capstone-banner-heading span {
    width: 257px;
  }
  .bannerImageHeadingSectionInCapstone h3 {
    font-size: 27px;
    line-height: 37px;
  }
  .bannerImageHeadingSectionInCapstone p {
    font-size: 19px;
  }
  .bannerImageHeadingSectionInCapstone .capstoneWriteMyProjectButton {
    margin-top: 0%;
  }
  .bannerImageHeadingSectionInCapstone p {
    padding: 0 41% 0 0;
  }
  .howToWriteCapstonePara table tr th {
    top: 21px;
  }
  .capstoneStatisticsSmallSection {
    width: 37%;
  }
  .capstoneStatisticsInnerDiv h2 {
    font-size: 29px;
  }
  .dissertationCapstoneRightSideImageFaqSection {
    margin-top: 5%;
  }
}
@media only screen and (max-width: 586px) {
  .howToWriteCapstonePara table tr th {
    top: 17px;
  }
}
@media only screen and (max-width: 492px) {
  .dissertationCapstoneBannerImage {
    background-position: initial;
  }
  .bannerImageHeadingSectionInCapstone p {
    padding: 0 0% 0 0;
    font-size: 21px;
  }
  .bannerImageHeadingSectionInCapstone h1 {
    font-size: 33px;
  }
  .bannerImageHeadingSectionInCapstone h3 {
    font-size: 27px;
  }
  .bannerImageHeadingSectionInCapstone .capstoneWriteMyProjectButton {
    margin-top: 2%;
    font-size: 21px;
  }
  .capstoneFirstQuestionHeadingAndPara h2,
  .howToWriteCapstoneHeading h2 {
    font-size: 25px;
  }
  .capstoneStatisticsSmallSection {
    width: 41%;
  }
  .capstoneStatisticsInnerDiv h2 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 472px) {
  .howToWriteCapstonePara table tr th {
    top: 13px;
  }
}
@media only screen and (max-width: 425px) {
  .bannerImageHeadingSectionInCapstone h1 {
    font-size: 26px;
  }
  .bannerImageHeadingSectionInCapstone h3 {
    font-size: 23px;
  }
  .below-heading-line-capstone-banner-heading span {
    width: 243px;
  }
  .bannerImageHeadingSectionInCapstone p {
    font-size: 17px;
  }
  .bannerImageHeadingSectionInCapstone .capstoneWriteMyProjectButton {
    margin-top: 0%;
  }
  .capstoneFirstQuestionHeadingAndPara h2,
  .howToWriteCapstoneHeading h2,
  .dissertationCapstoneFaqAndHelpNowSection h2,
  .capstoneProjectLastQuestion h2 {
    font-size: 21px;
  }
  .dissertationCapstoneImportantServices ul {
    width: 100%;
  }
  .dissertationCapstoneFaqAndHelpNowSection
    .capstoneProjectGetDissertationHelpNow {
    font-size: 15px;
  }
  .capstoneStatisticsSmallSection .capstoneStatisticsPara {
    font-size: 13px;
  }
  .capstoneStatisticsSmallSection .capstoneStatisticsData span {
    left: 16%;
    top: 33%;
    font-size: 19px;
  }
  .capstoneStatisticsSmallSection .capstoneStatisticsData {
    width: 101px;
    height: 101px;
  }
}
@media only screen and (max-width: 375px) {
  .bannerImageHeadingSectionInCapstone h1 {
    font-size: 24px;
  }
  .bannerImageHeadingSectionInCapstone h3 {
    font-size: 22px;
  }
  .bannerImageHeadingSectionInCapstone p {
    font-size: 17px;
  }
  .howToWriteCapstonePara table tr th {
    top: 9px;
  }
  .capstoneStatisticsSmallSection {
    width: 48%;
  }
}
@media only screen and (max-width: 320px) {
  .bannerImageHeadingSectionInCapstone h1 {
    font-size: 21px;
  }
  .dissertationCapstoneFaqAndHelpNowSection
    .capstoneProjectGetDissertationHelpNow {
    font-size: 15px;
  }
  .bannerImageHeadingSectionInCapstone p {
    font-size: 17px;
}
  .dissertationCapstoneFaqAndHelpNowSection h2,
  .capstoneProjectLastQuestion h2,
  .capstoneFirstQuestionHeadingAndPara h2,
  .howToWriteCapstoneHeading h2,
  .bannerImageHeadingSectionInCapstone h3,
  .capstoneStatisticsInnerDiv h2 {
    font-size: 17px;
  }
  .bannerImageHeadingSectionInCapstone .capstoneWriteMyProjectButton {
    font-size: 19px;
}
.below-heading-line-capstone-banner-heading span {
    width: 176px;
    margin-top: -5.5%;
}
  .howToWriteCapstonePara table tr th {
    top: 7px;
  }
  .capstoneStatisticsSmallSection {
    width: 51%;
  }
}
