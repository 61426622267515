
.getQuotePageFirstInnerDiv {
    background-image: url('../Image/Dissertation_GetQuote/Get_Quote_113.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 473px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.below-heading-line-get_quote span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffe02b;
    width: 337px;
    height: 3px;
    margin-top: 5%;
    margin-bottom: 2%;
}

.getQuotePageFirstInnerDiv h2 {
    text-align: center;
    color: #006ca2;
    font-size: 58px;
    line-height: 35px;
    font-weight: 600;
}

.getQuotePageFormSectionStartHere {
    background: #E3F1F7;
    padding: 5% 15%;
}

.getQuotePageFormSectionFirstInnerDiv {
    background-color: #fff;
    padding: 5% 9%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px; 
}

.getQuoteFormInputDiv label, .getQuoteFormInputDiv select option {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #006ca2;
}

.getQuoteFormInputDiv .getQuoteFormLabel, .getQuoteFormInputDiv select option {
    padding: 0 7%;
}

.getQuoteFormInputDiv label span {
    color: red;
}

.getQuoteFormInputDiv .getQuoteFormInputForPutValue, .getQuoteFormInputDiv select, .getQuoteFormInputDiv textarea {
    border: 1px solid #006ca2;
    outline: none;
    width: 100%;
}

.getQuoteFormSelectPhoneCodeDiv {
    position: relative;
    left: -10px;
}

.getQuoteFormInputDiv textarea::placeholder {
    padding: 0.5% 2%;
}

.getQuoteFormInputDiv textarea {
    border-radius: 4px;
}

.form-select {
    color: #006ca2 !important;
    font-weight: 500;
}

.getQuoteFormInputDiv input::placeholder, .getQuoteFormInputDiv textarea::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
}

.getQuoteFormYesButton, .getQuoteFormNoButton, .getQuoteFormSingleSpaceButton, .getQuoteFormDoubleSpaceButton, .getQuoteFormSubmitButton {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding: 1% 3%;
    border-radius: 4px;
    outline: none;
}

.getQuoteFormYesButton, .getQuoteFormNoButton {
    background: #FFFFFF;
    border: 1px solid #006ca2;
    color: #006ca2;
}

.getQuoteFormSingleSpaceButton, .getQuoteFormDoubleSpaceButton {
    border: 1px solid #006ca2;
}

.getQuoteFormSingleSpaceButton {
    background: #FFFFFF;
    color: #006ca2;
}

.getQuoteFormDoubleSpaceButton {
    background-color: #3389B5;
    color: #FFFFFF;
}

.col-md-12.mb-4.getQuoteFormInputDiv.getQuoteFormSubmitButtonDiv {
    position: relative;
    left: 3%;
}

.getQuoteFormSubmitButton {
    background: #006ca2;
    color: #FFFFFF;
    border: 1px solid #3389B5;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    border-radius: 0px;
    padding: 1.5% 4%
}

.getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv {
    padding: 0 7% 0 0;
}

.getQuoteFormInputDiv .getQuoteFormRadioButtonWordsOrPages {
    position: relative;
    left: -12px;
}

.getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
    color: #006ca2;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    left: -51px;
    top: 15%;
}

.getQuoteFormInputForPutValue {
    border: 1px solid #006CA2;
    color: #006CA2;
    font-weight: 500;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -43px;
    }
    .wrg-toggle-check {
        left: 125px;
    }
}
@media only screen and (max-width: 1000px) {
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -35px;
    }
}
@media only screen and (max-width: 991px) {
    .getQuotePageFirstInnerDiv {
        height: 451px;
    }
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -29px;
    }
    .wrg-toggle-uncheck span, .wrg-toggle-check span {
        left: -47px;
    }
}
@media only screen and (max-width: 868px) {
    .getQuotePageFirstInnerDiv h2 {
        font-size: 49px;
    }
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -27px;
    }
}
@media only screen and (max-width: 767px) {
    .getQuotePageFirstInnerDiv {
        height: 415px;
    }
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv {
        padding: 3% 7% 0 9%;
    }
    .col-md-12.mb-4.getQuoteFormInputDiv.getQuoteFormSubmitButtonDiv {
        left: 9%;
    }
    .getQuoteFormRadioButtonWordsOrPages {
        position: relative;
        top: 7px;
        left: 7%;
    }
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -51px;
    }
    .getQuoteFormInputDiv .getQuoteFormRadioButtonWordsOrPages {
        left: 29px;
        margin-top: 3%;
    }
    .getQuoteFormSelectPhoneCodeDiv {
        left: -17px;
        top: 14px;
    }
    .getQuoteFormSelectPhoneCodeDiv .getQuoteFormSelectPhoneCode {
        width: 135px !important;
    }
    .getQuoteFormSeccondInnerInputDiv .getQuoteFormInputPhoneNumber {
        width: 193px !important;
        position: relative;
        left: -35px;
        margin-top: 1px;
    }
    .col-sm-7.col-10.getQuoteFormSeccondInnerInputDiv {
        width: 82% !important;
    }
}
@media only screen and (max-width: 700px) {
    .getQuotePageFirstInnerDiv h2 {
        font-size: 41px;
    }
    .below-heading-line-get_quote span {
        width: 299px;
        margin-top: 4%;
    }
    .getQuoteFormSeccondInnerInputDiv .getQuoteFormInputPhoneNumber {
        width: 169px !important;
        left: -27px;
        margin-top: 2.5px;
    }
}
@media only screen and (max-width: 613px) {
    .getQuoteFormSeccondInnerInputDiv .getQuoteFormInputPhoneNumber {
        width: 153px !important;
        left: -29px;
        margin-top: 3.5px;
    }
    .getQuoteFormSelectPhoneCodeDiv {
        top: 13px;
    }
    .getQuoteFormSelectPhoneCodeDiv .getQuoteFormSelectPhoneCode {
        width: 115px !important;
    }
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -43px;
    }
}
@media only screen and (max-width: 575px) {
    .getQuotePageFormSectionStartHere {
        padding: 5% 3%;
    }
    .getQuoteFormSelectPhoneCodeDiv {
        left: 25px;
        top: 12px;
    }
    .getQuoteFormSeccondInnerInputDiv .getQuoteFormInputPhoneNumber {
        width: 100% !important;
        left: 0px;
        margin-top: 0px;
    }
}
@media only screen and (max-width: 509px) {
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        left: -45px;
    }
    .getQuoteFormSeccondInnerInputDiv .getQuoteFormInputPhoneNumber {
        margin-top: 1px;
    }
}
@media only screen and (max-width: 477px) {
    .col-sm-7.col-10.getQuoteFormSeccondInnerInputDiv {
        width: 70% !important;
        position: relative;
        left: -13px;
    }
    .getQuoteFormSelectPhoneCodeDiv .getQuoteFormSelectPhoneCode {
        width: 100% !important;
    }
    .getQuoteFormSelectPhoneCodeDiv {
        left: 19px;
        top: 11px;
    }
}
@media only screen and (max-width: 425px) {
    .getQuotePageFirstInnerDiv {
        height: 351px;
    }
    .getQuotePageFirstInnerDiv h2 {
        font-size: 29px;
    }
    .below-heading-line-get_quote span {
        width: 185px;
        margin-top: 2%;
    }
}
@media only screen and (max-width: 409px) {
    .getQuoteFormSelectPhoneCodeDiv {
        top: 8px;
    }
    .getQuoteFormSeccondInnerInputDiv .getQuoteFormInputPhoneNumber {
        margin-top: -1px;
    }
}
@media only screen and (max-width: 371px) {
    .getQuoteFormSelectPhoneCodeDiv {
        top: 7px;
        left: 11px;
    }
    .getQuoteFormInputDiv .getQuoteFormSeccondInnerInputDiv .wordsOrPages {
        font-size: 17px;
        top: 19%;
    }
    .getQuoteFormRadioButtonWordsOrPages input.radioInputPages {
        margin-left: 2rem !important;
    }
}
@media only screen and (max-width: 360px) {
    .getQuotePageFirstInnerDiv {
        height: 289px;
    }
    .getQuotePageFirstInnerDiv h2 {
        font-size: 25px;
    }
    .below-heading-line-get_quote span {
        width: 165px;
        margin-top: 0%;
    }
}
@media only screen and (max-width: 320px) {
    .getQuoteFormInputDiv .getQuoteFormRadioButtonWordsOrPages {
        left: 0px;
    }
}


