

.footerSectionStartHere {
    padding: 3% 3%;
}

.footerSectionLinksStartHere {
    background: linear-gradient(93.03deg, rgba(0, 108, 162, 0.2) 7.36%, rgba(16, 117, 168, 0.2) 12.17%, rgba(255, 255, 255, 0.2) 28.67%), linear-gradient(274.05deg, #006CA2 0.48%, rgba(255, 255, 255, 0) 42.37%);;
    padding: 3% 1% 0.3% 1%;
}

/* Site Links Section Start Here  */

.dissertationChapersLinks, .siteLinks, .subjectsLinks {
    position: relative;
    left: 1%;
}

.dissertation-links, .subjects-links, .site-links {
    color: #006CA2;
}

/* .dissertation-links span::after {
    content: "|";
    color: #006CA2;
} */

.siteLinks {
    position: relative;
    left: 9.5%;
}

.subjectsLinks {
    position: relative;
    left: 5%;
}


.siteLinks h5, .subjectsLinks h5, .dissertationChapersLinks h5 {
    color: #006CA2;
    /* font-family: 'Jost'; */
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
}

.siteLinks h5 span, .subjectsLinks h5 span, .dissertationChapersLinks h5 span {
    border-bottom: 1px solid rgba(0, 108, 162, 0.28);
}

.site-links span a, .subjects-links span a, .dissertation-links span a {
    color: #006CA2;
    text-decoration: none;
    /* font-family: 'Jost'; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.site-links span a:hover, .subjects-links span a:hover, .dissertation-links span a:hover {
    color: #61CAFF;
}

.siteLinks {
    border-right: 1px solid rgba(0, 108, 162, 0.2);
    /* border: 1px solid rgba(0, 108, 162, 0.2); */
}

/* Social Media Section Start Here  */

.socialMediaLinks {
    position: relative;
    left: 3.5%;
}

.socialMediaLinks ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.socialMediaLinks ul li {
    list-style: none;
    margin: 1rem 1rem;
    background-color: #006CA2;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}

.socialMediaLinks ul li i {
    position: relative;
    text-decoration: none;
    color: #fff;
    font-size: 17px;
    margin-left: 9px;
    top: 3px;
}

.socialMediaLinks ul li:hover {
    background-color: #61CAFF;
}

/* Last Footer Section Start Here  */

.lastFooterSection p {
    color: #006CA2;
    text-align: center;
    /* font-family: 'Jost', sans-serif; */
    font-size: 16px;
    font-weight: 400;
}

.lastFooterSection p a {
    text-decoration: underline;
    color: #006CA2;
}

.lastFooterSection p a:hover {
    color: #000;
}

/* Responsive Footer  */

@media only screen and (max-width: 992px) {
    .footerSectionLinksStartHere {
        padding: 2% 0.3% 0.3% 0.3%;
    }
    .dissertationChapersLinks, .siteLinks, .subjectsLinks {
        left: 2%;
    }
    .siteLinks h5, .subjectsLinks h5, .dissertationChapersLinks h5 {
        font-size: 15px;
    }
    .socialMediaLinks {
        left: 0%;
    }
    .siteLinks {
        left: 7%;
    }
    .subjectsLinks {
        left: 5%;
    }
}

@media only screen and (max-width: 991px) {
    /* .footerSectionLinksStartHere {
        padding: 2% 0.3% 0.3% 0.3%;
    }
    .dissertationChapersLinks, .siteLinks, .subjectsLinks {
        left: 2%;
    } */
    .siteLinks h5, .subjectsLinks h5, .dissertationChapersLinks h5 {
        font-size: 13px;
    }
    .site-links span a, .subjects-links span a, .dissertation-links span a {
        font-size: 12px;
    }
    .siteLinks {
        left: 5%;
    }
}

@media only screen and (max-width: 767px) {
    .footerSectionLinksStartHere {
        padding: 3% 2% 0.3% 2%;
    }
    .dissertationChapersLinks, .siteLinks, .subjectsLinks {
        left: 2%;
    }
    .subjectsLinks, .siteLinks, .socialMediaLinks {
        margin-top: 1.5rem;
    }
    .socialMediaLinks {
        left: -3%;
    }
    .socialMediaLinks ul li {
        margin: 1rem 1rem;
    }
    .socialMediaLinks ul div {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }
    .lastFooterSection p {
        font-size: 14px;
    }
    .siteLinks {
        border-right: 0;
    }
}

@media only screen and (max-width: 240px) {
    .socialMediaLinks ul li {
        margin: 0.5rem 0.5rem;
    }
    .lastFooterSection p {
        font-size: 11px;
    }
    .dissertationChapersLinks, .siteLinks, .subjectsLinks {
        left: 0%;
    }
}