
@import url('https://fonts.googleapis.com/css2?family=Laila:wght@300;400;500;600;700&display=swap');

.notFoundErrorPageInnerDiv .errorPageImageDiv img {
    width: 593px;
    height: 593px;
    margin-left: -10px;
}

.notFoundErrorPageInnerDiv .errorPageHeadingAndButton .firstHeading {
    position: relative;
    right: 189px;
    font-family: 'Laila', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 250px;
    line-height: 388px;
    text-align: center;
    color: #006CA2;
}

.notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
    position: relative;
    right: 129px;
    top: -73px;
    font-weight: 700;
    font-size: 31px;
    line-height: 52px;
    color: rgba(0, 108, 162, 0.8);
}

.notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
    position: relative;
    right: 82px;
    top: -49px;
    font-weight: 700;
    font-size: 23px;
    line-height: 52px;
    background-color: #006CA2;
    padding: 1.5% 9%;
    border-radius: 12px;
    outline: none;
    border: none;
}

.notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton a {
    text-decoration: none;
    color: #FFFFFF;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .notFoundErrorPageInnerDiv .errorPageImageDiv img {
        width: 639px;
        height: 541px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
        right: 105px;
    }
}
@media only screen and (max-width: 1100px) {
    .notFoundErrorPageInnerDiv .errorPageImageDiv img {
        width: 555px;
        height: 525px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
        right: 105px;
    }
}
@media only screen and (max-width: 1067px) {
    .notFoundErrorPageInnerDiv .errorPageImageDiv img {
        width: 514px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        right: 139px;
        font-size: 29px;
    }
}
@media only screen and (max-width: 1002px) {
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .firstHeading {
        right: 164px;
        font-size: 201px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        top: -97px;
        font-size: 27px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
        right: 119px;
        top: -81px;
    }
}
@media only screen and (max-width: 950px) {
    .notFoundErrorPageInnerDiv .errorPageImageDiv img {
        width: 467px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .firstHeading {
        right: 129px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        right: 109px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
        right: 87px;
    }
}
@media only screen and (max-width: 900px) {
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        font-size: 25px;
    }
}
@media only screen and (max-width: 874px) {
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        right: 82px;
        font-size: 22px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
        right: 71px;
        top: -91px;
        font-size: 23px;
        padding: 1.5% 12%;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        top: -107px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .firstHeading {
        right: 112px;
        font-size: 171px;
    }
}
@media only screen and (max-width: 800px) {
    .notFoundErrorPageInnerDiv .errorPageImageDiv img {
        width: 417px;
    }
}
@media only screen and (max-width: 778px) {
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        font-size: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .notFoundErrorPageInnerDiv .errorPageImageDiv img {
        width: 100%;
        height: auto;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .firstHeading {
        right: 0;
        text-align: center;
        font-size: 201px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        font-size: 29px;
        text-align: center;
        right: 0;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .errorPageButton {
        right: 0;
        display: flex;
        justify-content: center;
        margin: auto;
    }
}
@media only screen and (max-width:320px) {
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .secondHeading {
        font-size: 27px;
    }
    .notFoundErrorPageInnerDiv .errorPageHeadingAndButton .firstHeading {
        font-size: 175px;
    }
}