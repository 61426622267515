.commonComponentFaqSection h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.commonComponentFaqAndDissertationChapterSection {
    padding: 3% 0;
}

/* FAQ Section Start Here  */

.accordion {
    --bs-accordion-btn-padding-x: 0.25rem !important;
    --bs-accordion-btn-padding-y: 1rem !important;
}
/* * {
    border: 1px solid orange;
} */

.accordion-item h2 button {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #3389B5;
}

.accordion-item .accordion-body {
    font-size: 17px;
    color: #3389B5;
    line-height: 31px;
}

.commonComponentFaqSection .commonComponentReadMoreOnFaqSection {
    margin: 1% 0;
}

.commonComponentFaqSection .commonComponentReadMoreOnFaqSection span {
    border-bottom: 2px solid #FFE02B;
}

.commonComponentFaqSection .commonComponentReadMoreOnFaqSection a {
    text-decoration: none;
    color: #006CA2;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.02em;
}

.commonComponentChapters {
    padding: 0 7%;
}

.commonComponentChapters ul {
    background: #FFFFFF;
    border-top: 5px solid #3389B5;
    border-left: 1px solid #3389B5;
    border-bottom: 1px solid #3389B5;
    border-right: 1px solid #3389B5;
    border-radius: 10px 10px;
    /* height: 375px; */
    width: 375px;
}

.commonComponentChapters ul li {
    list-style: none;
    margin: 1% 1%;
    padding: 1% 0;
}

.commonComponentChapters ul li img {
    width: 15px;
}

.commonComponentChapters ul li a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    text-decoration-line: underline;
    color: #3389B5;
}

.commonComponentChapters h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #3389B5;
    margin-bottom: 2%;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1100px) {
    .commonComponentChapters {
        padding: 0 0%;
    }
    .commonComponentFaqAndDissertationChapterSection {
        padding: 3% 3%;
    }
}
@media only screen and (max-width: 991px) {
    .commonComponentChapters {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3%;
        position: relative;
        left: 11%;
    }
    .commonComponentFaqSection {
        position: relative;
        left: 0%;
    }
}
@media only screen and (max-width: 850px) {
    .commonComponentFaqSection {
        left: 0%;
    }
}
@media only screen and (max-width: 425px) {
    .commonComponentFaqSection h2 {
        font-size: 25px;
    }
    .commonComponentChapters ul {
        width: 301px;
    }
    .commonComponentFaqAndDissertationChapterSection {
        padding: 3% 1%;
    }
    .accordion-item h2 button {
        font-size: 15px;
    }
    .commonComponentChapters {
        left: 9%;
    }
    .commonComponentFaqSection {
        left: 0%;
    }
    .commonComponentChapters ul li a {
        font-size: 14px;
    }
}
@media only screen and (max-width: 320px) {
    .commonComponentFaqSection h2 {
        font-size: 22px;
    }
    .commonComponentChapters h4 {
        font-size: 16px;
    }
    .commonComponentChapters ul {
        width: 301px !important;
    }
    .commonComponentFaqSection h2 {
        font-size: 19px;
    }
}