

.dissertationIntroductionInnerDivOfBannerImageSection {
    background-image: url('../Image/Dissertation_Introduction/21.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 475px;
}

.dissertationIntroductionFirstThreeQuestionSection {
    margin: 5% 5%;
    padding: 0 0%;
}

.dissertationIntroductionHeadingAndPara h2, .dissertationIntroductionFourthQuestion h2, .dissertationIntroductionFifthQuestion h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.dissertationIntroductionHeadingAndPara p, .dissertationIntroductionFourthQuestion p, .dissertationIntroductionFifthQuestion p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
}

.dissertationIntroductionGetExpertHelpNowButton button, .dissertationIntroductionFourthQuestion button, .dissertationIntroductionFifthQuestion button {
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 1% 5%;
}

.dissertationIntroductionGetExpertHelpNowButton button a, .dissertationIntroductionFourthQuestion button a, .dissertationIntroductionFifthQuestion button a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 17px;
    text-decoration: none;
}

.dissertationIntroductionFifthQuestionAndTestimonial {
    padding: 3% 0 3% 0;
}

.dissertationIntroductionFourthQuestion {
    margin-top: 3%;
}

.dissertationIntroductionFourthQuestion button, .dissertationIntroductionFifthQuestion button {
    padding: 2% 7%;
    margin: 3% 0 7% 0;
}

.dissertationIntroductionChapters {
    padding: 0 7%;
}

.dissertationIntroductionChapters h4, .dissertationIntroductionTestimonial h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #3389B5;
    margin-bottom: 2%;
}

.dissertationIntroductionChapters ul {
    background: #FFFFFF;
    border-top: 5px solid #3389B5;
    border-left: 1px solid #3389B5;
    border-bottom: 1px solid #3389B5;
    border-right: 1px solid #3389B5;
    border-radius: 10px 10px;
    width: 375px;
}

.dissertationIntroductionChapters ul li {
    list-style: none;
    margin: 3% 1%;
    padding: 2% 0;
}

.dissertationIntroductionChapters ul li img {
    width: 15px;
}

.dissertationIntroductionChapters ul li a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    text-decoration-line: underline;
    color: #3389B5;
}

/* Carousel Part Start Here  */

.dissertationIntroductionCarousel {
    background-image: url('../Image/Dissertation_Introduction/22.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 431px;
    height: 351px;
}

.dissertationIntroductionCarouselItem p {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 25%;
    color: #006CA2;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    padding: 5% 13%;
}

.dissertationIntroductionInnerPartOfCarousel {
    background-color: transparent !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  font-size: 19px;
  color: #006ca2;
  font-weight: 700;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1200px) {
    .dissertationIntroductionFourthQuestionAndTestimonial {
        padding: 0 5%;
    }
}
@media only screen and (max-width: 1100px) {
    .dissertationIntroductionChapters {
        padding: 0 0%;
    }
    .dissertationIntroductionTestimonial {
        position: relative;
        right: 7%;
    }
}
@media only screen and (max-width: 1000px) {
    .dissertationIntroductionFifthQuestionAndTestimonial {
        padding: 3% 5%;
    }
}
@media only screen and (max-width: 991px) {
    .dissertationIntroductionFourthQuestion, .dissertationIntroductionFifthQuestion {
        margin-top: 0%;
        width: 100%;
    }
    .dissertationIntroductionChapters, .dissertationIntroductionTestimonial {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .dissertationIntroductionFifthQuestion button {
        margin: 3% 0 3% 0;
    }
    .dissertationIntroductionTestimonial {
        margin-top: 3%;
        right: 0%;
    }
}
@media only screen and (max-width: 807px) {
    .dissertationIntroductionInnerDivOfBannerImageSection {
        height: 411px;
    }
    .dissertationIntroductionFourthQuestion, .dissertationIntroductionFifthQuestion {
        padding: 0 2%;
    }
}
@media only screen and (max-width: 767px) {
    .dissertationIntroductionFourthQuestion, .dissertationIntroductionFifthQuestion {
        padding: 0 2%;
    }
}
@media only screen and (max-width: 574px) {
    .dissertationIntroductionHeadingAndPara h2, .dissertationIntroductionFourthQuestion h2, .dissertationIntroductionFifthQuestion h2 {
        font-size: 23px;
    }
    .dissertationIntroductionHeadingAndPara p, .dissertationIntroductionFourthQuestion p, .dissertationIntroductionFifthQuestion p {
        font-size: 15px;
    }
    .dissertationIntroductionGetExpertHelpNowButton button, .dissertationIntroductionFourthQuestion button, .dissertationIntroductionFifthQuestion button {
        padding: 2% 5%;
    }
    .dissertationIntroductionGetExpertHelpNowButton button a, .dissertationIntroductionFourthQuestion button a, .dissertationIntroductionFifthQuestion button a {
        font-size: 15px;
    }
    .dissertationIntroductionFourthQuestion, .dissertationIntroductionFifthQuestion {
        padding: 0 3%;
    }
}
@media only screen and (max-width: 425px) {
    .dissertationIntroductionInnerDivOfBannerImageSection {
        height: 349px;
    }
    .carousel {
        width: 100%;
    }
    .dissertationIntroductionHeadingAndPara h2, .dissertationIntroductionFourthQuestion h2, .dissertationIntroductionFifthQuestion h2 {
        font-size: 19px;
    }
    .dissertationIntroductionChapters ul {
        width: 349px;
    }
    .dissertationIntroductionChapters ul li a {
        font-size: 14px;
    }
}
@media only screen and (max-width: 320px) {
    .dissertationIntroductionChapters h4, .dissertationIntroductionTestimonial h4 {
        font-size: 15px;
    }
    .dissertationIntroductionChapters ul {
        width: 301px;
    }
}


/* CSS of Banner Form Start Here */

.dissertationIntroductionQuickQueryForm {
    width: 685px;
    height: auto;
    background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1.5%;
    position: relative;
    /* left: 3%; */
    top: 325px;
    margin: 0 auto;
  }
  
  .dissertationIntroductionQuickQueryForm .introductionEmail, .dissertationIntroductionQuickQueryForm .dissertationIntroductionFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 209px;
    padding: 2px;
    background: transparent;
  }
  
  .dissertationIntroductionFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }
  
    .dissertationIntroductionQuickQueryForm .introductionEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }
  
  .dissertationIntroductionQuickQueryForm .dissertationIntroductionFormSelect {
    padding: 2px 0;
  }
  
  .dissertationIntroductionQuickQueryForm .dissertationIntroductionFormSelect option {
    color: #006CA2;
    font-weight: 500;
  }
  
  .dissertationIntroductionSubmitButtonDiv {
    position: relative;
    top: 21px;
    display: flex;
    justify-content: center;
  }
  
  .dissertationIntroductionSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background-color: #006CA2;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 1% 2%;
    border-radius: 4px;
    margin-bottom: 4%;
  }
  
  .displayIntroduction {
    display: none;
  }

  /* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
    .dissertationIntroductionInnerDivOfBannerImageSection {
        height: 407px;
    }
    .dissertationIntroductionQuickQueryForm {
        top: 255px;
    }
  }
@media only screen and (max-width: 1300px) {
    .dissertationIntroductionQuickQueryForm {
        top: 229px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dissertationIntroductionQuickQueryForm {
        top: 253px;
    }
  }
  @media only screen and (max-width: 991px) {
    .dissertationIntroductionQuickQueryForm {
        top: 263px;
    }
  }
  @media only screen and (max-width: 767px) {
    .dissertationIntroductionQuickQueryForm {
      width: 525px;
      top: 173px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .introductionEmail.ms-2 {
      margin-left: 0rem !important;
    }
    .dissertationIntroductionQuickQueryForm .introductionEmail, .dissertationIntroductionQuickQueryForm .dissertationIntroductionFormSelect {
      width: 90%;
      margin: 6px 0;
  }
  .dissertationIntroductionSubmitButtonDiv {
    top: 14px;
  }
  .dissertationIntroductionSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-size: 16px;
    line-height: 23px;
    padding: 3% 0%;
    margin-bottom: 17%;
    width: 160px;
  }
  .dissertationIntroductionSubmitButtonDiv {
  margin-top: 5px;
  }
  }
  @media only screen and (max-width: 600px) {
    .dissertationIntroductionQuickQueryForm {
        top: 175px;
    }
  }
  @media only screen and (max-width: 575px) {
    .dissertationIntroductionBannerImageHeadingAndForm {
        padding: 0% 7%;
    }
    .dissertationIntroductionQuickQueryForm {
      left: 0%;
      width: 100%;
  }
  }