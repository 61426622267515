.dissertationPrivacyPolicyBannerImageSection {
    background-image: url('../Image/Dissertation_Privacy_Policy/Privacy_Policy.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 475px;
}

.dissertationPrivacyPolicySectionStartHere .dissertationPrivacyPolicyBannerHeadingAndPara {
   padding: 9%;
}

.dissertationPrivacyPolicyBannerHeadingAndPara .privacyPolicyHeading {
    font-weight: 500;
    font-size: 31px;
    line-height: 45px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicyFirstPara, .dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicySecondPara {
    font-size: 18px;
    line-height: 35px;
    color: #FFFFFF;
}

.dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicyFirstPara {
    font-weight: 300;
    text-align: justify;
    padding: 0 15% 0 0;
}

.dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicySecondPara {
    font-weight: 400;
}

.dissertationPrivacyPolicyQuestionsHeadingAndPara h2, .dissertationPrivacyPolicyLastQuestionHeadingAndTable h2 {
    color: #006CA2;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
}

.dissertationPrivacyPolicyQuestionsHeadingAndPara h2 {
    margin-top: 11%;
    margin-bottom: 3%;
}

.dissertationPrivacyPolicyLastQuestionHeadingAndTable h2 {
    margin: 3% 0 3% 0;
}

.dissertationPrivacyPolicyQuestionsHeadingAndPara p {
    color: #006CA2;
    font-weight: 300;
    font-size: 17px;
    line-height: 35px;
    text-align: justify;
}

.dissertationPrivacyPolicyBlogAndChapters {
    background: #E3F1F7;
    padding: 3%;
    margin-top: 6%;
    height: auto;
}

.dissertationPrivacyPolicyBlogAndChapters h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #3389B5;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable {
    background-color: #FFFFFF;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody {
    padding: 5%;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr {
    border: 1px solid rgba(0, 108, 162, 0.13)
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr th {
    font-weight: 500;
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(0, 108, 162, 0.53)
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr th, .dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td {
    padding: 7%;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td p, .dissertationPrivacyPolicyLastQuestionHeadingAndTable .dissertationPrivacyPolicyTable tbody tr td p {
    font-weight: 600;
    font-size: 17px;
    text-align: justify;
    line-height: 35px;
    color: #006CA2;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td p {
    margin-left: 11%;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td .dissertationPrivacyPolicyReadMoreButton {
    margin-top: -4%;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td .dissertationPrivacyPolicyReadMoreButton span a {
    text-decoration: none;
    color: #006CA2;
}

.dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td p span {
    border-bottom: 2px solid #ffe02b;
}

.dissertationPrivacyPolicyLastQuestionHeadingAndTable .dissertationPrivacyPolicyHeading img {
    width: 25px;
    margin-top: 9px;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .dissertationPrivacyPolicyBlogAndChapters {
        height: 993px;
    }
}
@media only screen and (max-width: 991px) {
    .dissertationPrivacyPolicyBlogAndChapters {
        height: auto;
    }
    .dissertationPrivacyPolicyBannerImageSection {
        height: 401px;
    }
    .dissertationPrivacyPolicySectionStartHere .dissertationPrivacyPolicyBannerHeadingAndPara {
        padding: 7%;
    }
    .dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td p {
        margin-left: 3%;
    }
    .dissertationPrivacyPolicyBlogAndChapters {
        padding: 5%;
    }
}
@media only screen and (max-width: 767px) {
    .dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicyFirstPara {
        padding: 0px 41% 0 0;
    }
}
@media only screen and (max-width: 574px) {
    .dissertationPrivacyPolicyBannerHeadingAndPara .privacyPolicyHeading {
        font-size: 27px;
    }
    .dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicyFirstPara {
        padding: 0px 25% 0 0;
    }
    .dissertationPrivacyPolicyQuestionsHeadingAndPara h2, .dissertationPrivacyPolicyLastQuestionHeadingAndTable h2 {
        font-size: 25px;
    }
    .dissertationPrivacyPolicyBlogAndChapters .dissertationPrivacyPolicyTable tbody tr td p, .dissertationPrivacyPolicyLastQuestionHeadingAndTable .dissertationPrivacyPolicyTable tbody tr td p, .dissertationPrivacyPolicyQuestionsHeadingAndPara p, .dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicyFirstPara, .dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicySecondPara {
        font-size: 15px;
    }
    .dissertationPrivacyPolicyQuestionsHeadingAndPara, .dissertationPrivacyPolicyLastQuestion {
        padding: 0 5%;
    }
}
@media only screen and (max-width: 500px) {
    .dissertationPrivacyPolicyBannerImageSection {
        height: 349px;
    }
}
@media only screen and (max-width: 425px) {
    .dissertationPrivacyPolicyBannerHeadingAndPara .dissertationPrivacyPolicyFirstPara {
        padding: 0px 3% 0 0;
    }
}
