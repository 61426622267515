
.dissertationProposalInnerDivOfBannerImage {
    background-image: url('../Image/Dissertation_Proposal/Proposal.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 475px;
}

.dissertationProposalBannerImageHeadingAndForm {
    margin: 11px;
    padding: 1% 3%;
    /* background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%); */
    /* border: 1px solid rgba(255, 255, 255, 0.2); */
    /* box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25); */
    /* width: 651px; */
    /* border-radius: 4px; */
}

.dissertationProposalFirstThreeQuestionSection {
    margin: 5% 5% 1% 5%;
    padding: 0 0%;
}

.dissertationProposalHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.dissertationProposalHeadingAndParaSection p, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara p, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara p, .dissertationProposalHeadingAndParaSection ol li {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
}

.dissertationProposalHeadingAndParaSection button, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button {
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 1% 5%;
}

.whatResearchMethodsCanUseForMyDissertationHeadingAndPara button {
    padding: 2% 7%;
}

.dissertationProposalHeadingAndParaSection button a, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button a, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 17px;
    text-decoration: none;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 991px) {
    .hideThisDivSection {
        display: none;
    }
    .dissertationProposalBannerImageHeadingAndForm {
        padding: 3%;
        width: 601px;
    }
    .col-lg-5.col-10.whatResearchMethodsCanUseForMyDissertationHeadingAndPara {
        width: 100%;
    }
    .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara {
        margin-top: 5%;
    }
}
@media only screen and (max-width: 807px) {
    .dissertationProposalInnerDivOfBannerImage {
        height: 411px;
    }
}
@media only screen and (max-width: 600px) {
    .dissertationProposalInnerDivOfBannerImage {
        height: 349px;
    }
    .dissertationproposalRowSection {
        padding: 5% 9%;
    }
    .dissertationProposalBannerImageHeadingAndForm {
        width: 100%;
    }
    .dissertationProposalHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 23px;
    }
}
@media only screen and (max-width: 500px) {
    .dissertationProposalInnerDivOfBannerImage {
        height: auto;
    }
}
@media only screen and (max-width: 425px) {
    .dissertationProposalHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 23px;
    }
    .dissertationProposalHeadingAndParaSection p, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara p, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara p {
        font-size: 15px;
    }
    .dissertationProposalHeadingAndParaSection button, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button {
        padding: 3% 7%;
    }
    .dissertationProposalHeadingAndParaSection button a, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button a, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button a {
        font-size: 15px;
    }
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
    .dissertationProposalHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 21px;
    }
}

/* CSS of Banner Form Start Here */

.dissertationProposalQuickQueryForm {
    width: 679px;
    height: auto;
    background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1.5%;
    position: relative;
    left: 3%;
    top: 311px;
  }
  
  .dissertationProposalQuickQueryForm .proposalEmail, .dissertationProposalQuickQueryForm .dissertationProposalFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 209px;
    padding: 2px;
    background: transparent;
  }
  
  .dissertationProposalFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }
  
    .dissertationProposalQuickQueryForm .proposalEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }
  
  .dissertationProposalQuickQueryForm .dissertationProposalFormSelect {
    padding: 2px 0;
  }
  
  .dissertationProposalQuickQueryForm .dissertationProposalFormSelect option {
    color: #006CA2;
    font-weight: 500;
  }
  
  .dissertationProposalSubmitButtonDiv {
    position: relative;
    top: 21px;
    display: flex;
    justify-content: center;
  }
  
  .dissertationProposalSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background-color: #006CA2;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 1% 2%;
    border-radius: 4px;
    margin-bottom: 4%;
  }
  
  .displayProposal {
    display: none;
  }

  /* * {
    border: 1px solid orange;
  } */

  /* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
    .dissertationProposalInnerDivOfBannerImage {
        height: 403px;
    }
    .dissertationProposalQuickQueryForm {
        top: 231px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dissertationProposalQuickQueryForm {
        left: -4%;
        top: 243px;
    }
  }
  @media only screen and (max-width: 1050px) {
    .dissertationProposalQuickQueryForm {
    left: -10%;
    }
}
  @media only screen and (max-width: 991px) {
    .dissertationProposalQuickQueryForm {
        left: -11%;
        top: 235px;
    }
  }
  @media only screen and (max-width: 900px) {
    .dissertationProposalQuickQueryForm {
      left: -11%;
  }
  }
  @media only screen and (max-width: 767px) {
    .dissertationProposalQuickQueryForm {
      width: 525px;
      left: 0%;
      top: 135px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .proposalEmail.ms-2 {
      margin-left: 0rem !important;
    }
    .dissertationProposalQuickQueryForm .proposalEmail, .dissertationProposalQuickQueryForm .dissertationProposalFormSelect {
      width: 90%;
      margin: 6px 0;
  }
  .dissertationProposalSubmitButtonDiv {
    top: 14px;
  }
  .dissertationProposalSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-size: 16px;
    line-height: 23px;
    padding: 3% 0%;
    margin-bottom: 17%;
    width: 160px;
  }
  .dissertationProposalSubmitButtonDiv {
  margin-top: 5px;
  }
  }
  @media only screen and (max-width: 600px) {
    .dissertationProposalQuickQueryForm {
      left: -6%;
      top: 115px;
  }
  }
  @media only screen and (max-width: 575px) {
    .dissertationProposalQuickQueryForm {
      left: 0%;
      width: 100%;
  }
  }