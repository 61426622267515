
.dissertationSiteMapDiv li {
    margin: 1.5% 0;
    color: #006CA2;
    font-weight: 500;
    cursor: pointer;
}

.listItem {
    text-decoration: none;
    color: #006CA2;
}

.dissertationSiteMapDiv li:hover, .listItem:hover {
    color: #61CAFF;
}