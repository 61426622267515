.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .paginationItem, .blogPagePagination li .pageLinkButton {
    background: #fff;
    border: 2px solid #FFFFFF;
    padding: 10px 15px;
    height: 35px;
    width: 35px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
    color: #006CA2;
    font-weight: 500;
  }
  
  .paginationItem span, .blogPagePagination li .pageLinkButton span i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .prev,
  .next {
    background: #fff;
    border: none;
    padding: 10px;
    color: #006CA2 !important;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  }
  
  .paginationItem.active {
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    pointer-events: none;
    background-color: #006CA2;
  }
  
  .prev.disabled,
  .next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #006CA2;
  }

  /* Responsive Page Start Here */

  @media only screen and (max-width: 1199px) {
    ul.pagination.blogPagePagination {
        margin-left: 2%;
    }
  }
  @media only screen and (max-width: 850px) {
    ul.pagination.blogPagePagination {
        margin-left: 4%;
    }
  }
  @media only screen and (max-width: 767px) {
    ul.pagination.blogPagePagination {
        margin-left: 0%;
    }
  }
  @media only screen and (max-width: 425px) {
    .paginationItem, .blogPagePagination li .pageLinkButton {
        padding: 15px 15px;
        height: 25px;
        width: 25px;
        margin: 0 3px;
    }
  }
  @media only screen and (max-width: 375px) {
    .paginationItem, .blogPagePagination li .pageLinkButton {
        padding: 13px 13px;
        height: 15px;
        width: 15px;
        margin: 0 2px;
    }
  }

  