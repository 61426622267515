
.dissertationTermsAndConditionsBannerImageSection {
    background-image: url('../Image/Dissertation_Terms_And_Conditions/Terms_And_Conditions.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 475px;
}

.dissertationTermsAndConditionsHeadingAndPara h2 {
    font-weight: 400;
    font-size: 29px;
    line-height: 35px;
    letter-spacing: 0.05em;
    color: #006CA2;
    margin: 5% 0;
}

.dissertationTermsAndConditionsHeadingAndPara p {
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    color: #006CA2;
    text-align: justify;
}

.dissertationTermsAndConditionsBlogAndChapters h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #3389B5;
    margin-top: 5%;
    margin-bottom: 2%;
}

.dissertationTermsAndConditionsBlogAndChapters ul {
    background: #FFFFFF;
    border-top: 5px solid #3389B5;
    border-left: 1px solid #3389B5;
    border-bottom: 1px solid #3389B5;
    border-right: 1px solid #3389B5;
    border-radius: 10px 10px;
    /* height: 375px; */
    width: 349px;
}

.dissertationTermsAndConditionsBlogAndChapters ul li {
    list-style: none;
}

.dissertationTermsAndConditionsBlogAndChapters ul li a {
    text-decoration: underline;
    color: #3389B5;
    font-weight: 700;
    font-size: 16px;
    line-height: 51px;
}

.dissertationTermsAndConditionsBlogAndChapters ul li img {
    width: 19px;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable {
    background-color: #E3F1F7;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody {
    padding: 5%;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr {
    border: 1px solid rgba(0, 108, 162, 0.13)
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr th {
    font-weight: 500;
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(0, 108, 162, 0.53)
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr th, .dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td {
    padding: 7%;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td p {
    margin-left: 11%;
    font-weight: 600;
    font-size: 17px;
    text-align: justify;
    line-height: 35px;
    color: #006CA2;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td .dissertationTermsAndConditionsReadMoreButton {
    margin-top: -4%;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td .dissertationTermsAndConditionsReadMoreButton span a {
    text-decoration: none;
    color: #006CA2;
}

.dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td p span {
    border-bottom: 2px solid #ffe02b;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 991px) {
    .dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td p {
        margin-left: 3%;
    }
    .dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr th, .dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td {
        padding: 5%;
    }
    .dissertationTermsAndConditionsBlogAndChapters {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .dissertationTermsAndConditionsBlogAndChapters h4 {
        margin-top: 2%;
    }
    .dissertationTermsAndConditionsBlogAndChapters ul {
        width: 401px;
    }
}
@media only screen and (max-width: 500px) {
    .dissertationTermsAndConditionsBannerImageSection {
        height: 401px;
    }
    .dissertationTermsAndConditionsHeadingAndPara h2 {
        font-size: 25px;
    }
    .dissertationTermsAndConditionsHeadingAndPara p, .dissertationTermsAndConditionsBlogAndChapters .dissertationTermsAndConditionsTable tbody tr td p {
        font-size: 15px;
    }
    .dissertationTermsAndConditionsHeadingAndPara {
        padding: 0 5%;
    }
    .dissertationTermsAndConditionsBlogAndChapters ul {
        width: 301px;
    }
}
@media only screen and (max-width: 375px) {
    .dissertationTermsAndConditionsBannerImageSection {
        height: 269px;
    }
}
@media only screen and (max-width: 320px) {
    .dissertationTermsAndConditionsHeadingAndPara h2 {
        font-size: 22px;
    }
    .dissertationTermsAndConditionsBlogAndChapters ul {
        width: 100%;
    }
}