
body {
    font-family: 'Jost' !important;
}

.testimonialBannerSectionFirstInnerDiv {
    background-image: url('../Image/Testimonial_Page/Banner_Image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 455px;
}

.givingLineBelowBannerImage .givingLineBelowTheUpperDiv {
    background: #006CA2;
    width: 100%;
    height: 1px;
    margin: 5% 0;
}

.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 17px !important;
}

.swiper-button-next, .swiper-button-prev {
    top: 25% !important;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -9px !important;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -9px !important;
}

.testimonialCardSection .testimonialCardBody {
    padding: 5% 6%;
}

.testimonialCardSection .testimonialCardImage {
    width: 325px !important;
    height: 251px !important;
}

.testimonialCardSection .testimonialCardBody .testimonialCardHeading {
    color: #006CA2;
    font-weight: 400;
    font-size: 23px;
    line-height: 46px;
    letter-spacing: 0.1em;
}

.testimonialCardSection .testimonialCardBody .testimonialCardPara {
    color: #006CA2;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    text-align: justify;
}

.testimonialCardSection .testimonialCardBody .testimonialCardStudentName {
    color: #006CA2;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.1em;
}

.dissertationTestimonialQuoteSectionStartHere .dissertationTestimonialQuote {
    color: #006CA2;
    font-weight: 500;
    font-size: 20px;
    line-height: 37px;
    text-align: center;
    padding: 0 15%;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 575px) {
    .testimonialCardSection .testimonialCardImage {
        width: 100% !important;
    }
    .dissertationTestimonialQuoteSectionStartHere .dissertationTestimonialQuote {
        font-size: 17px;
        padding: 0 5%;
    }
    .testimonialCardSection .testimonialCardBody {
        padding: 5% 3%;
    }
}