
.dissertationBlogPageFirstInnerDivStartHere {
    background-color: #E3F1F7;
    padding: 3%;
    margin-bottom: 3%;
}

.dissertation-blog-card-first-inner-div {
    height: 100%;
    background: #FFFFFF;
}

.dissertationBlogPageBlogCard {
    /* background-color: #FFFFFF; */
    margin-bottom: 3%;
    width: 27vw;
}

.dissertationBlogPageBlogCard .dissertationPageCardBody {
    padding: 11% 5%;
    background-color: #FFFFFF;
}

.dissertationBlogPageFirstInnerDivStartHere .dissertationBlogPageBlogCard img {
    height: 37vh !important;
    border-radius: 10px 10px 0 0;
}

.dissertationBlogPageBlogCard .dissertationPageCardBody h5 {
    color: #006CA2;
    font-weight: 500;
    line-height: 35px;
}

.dissertationBlogPageBlogCard .dissertationPageCardBody .dissertationBlogPageFirstPara {
    color: #006CA2;
    font-weight: 300;
    line-height: 35px;
    text-align: justify;
}

.dissertationBlogPageBlogCard .dissertationPageCardBody .dissertationBlogPageReadMoreButton span {
    border-bottom: 2px solid #ffe02b;
}

.dissertationBlogPageBlogCard .dissertationPageCardBody .dissertationBlogPageReadMoreButton span a {
    text-decoration: none;
    color: #006CA2;
    font-weight: 500;
}

.dissertationBlogPageLatestBlogSection h4 {
    font-weight: 500;
    color: #006CA2;
    font-size: 18px;
    line-height: 35px;
}

.dissertationBlogPageLatestBlogSection {
    position: relative;
    right: 3%;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable {
    background: #FFFFFF;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody {
    padding: 5%;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr {
    border: 1px solid rgba(0, 108, 162, 0.13)
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr th {
    font-weight: 500;
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.05em;
    color: rgba(0, 108, 162, 0.53)
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr th, .dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td {
    padding: 7%;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td p, .dissertationBlogPageLastQuestionHeadingAndTable .dissertationBlogPageTable tbody tr td p {
    font-weight: 600;
    font-size: 17px;
    line-height: 35px;
    color: #006CA2;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td p {
    margin-left: 11%;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td .dissertationBlogPageReadMoreButton {
    margin-top: -4%;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td .dissertationBlogPageReadMoreButton span a {
    text-decoration: none;
    color: #006CA2;
}

.dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td p span {
    border-bottom: 2px solid #ffe02b;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr th, .dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td {
        padding: 5%;
    }
    .dissertationBlogPageBlogCard {
        width: 25vw;
    }
}
@media only screen and (max-width: 991px) {
    .dissertationBlogPageLatestBlogSection {
        right: 0%;
        padding: 3% 5%;
    }
    .dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td p {
        margin-left: 0%;
    }
    .dissertationBlogPageBlogCard {
        width: 30vw;
    }
    .dissertationBlogPageFirstInnerDivStartHere .dissertationBlogPageBlogCard img {
        height: 39vh !important;
    }
    .dissertationBlogPageBlogCard .dissertationPageCardBody {
        padding: 7% 5%;
    }
}
@media only screen and (max-width: 850px) {
    .dissertationBlogPageBlogCard {
        width: 35vw;
    }
}
@media only screen and (max-width: 800px) {
    .dissertationBlogPageBlogCard {
        width: 38vw;
    }
}
@media only screen and (max-width: 767px) {
    .dissertationBlogPageFirstInnerDivStartHere .dissertationBlogPageBlogCard img {
        height: 55vh !important;
    }
    .dissertationBlogPageBlogCard {
        width: 100%;
    }
}
@media only screen and (max-width: 425px) {
    .dissertationBlogPageFirstInnerDivStartHere .dissertationBlogPageBlogCard img {
        height: 43vh !important;
    }
    .dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td p {
        margin-left: 5%;
    }
    .dissertationBlogPageLatestBlogSection .dissertationBlogPageTable tbody tr td p, .dissertationBlogPageLastQuestionHeadingAndTable .dissertationBlogPageTable tbody tr td p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 320px) {
    .dissertationBlogPageFirstInnerDivStartHere .dissertationBlogPageBlogCard img {
        height: 37vh !important;
    }
}
