
.dissertationConclusionInnerDivOfBannerImage {
    background-image: url('../Image/Dissertation_Conclusion/Conclusion.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    height: 475px;
}

/* .dissertationConclusionBannerImageSection::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
} */

.dissertationConclusionBannerImageHeadingAndForm {
    padding: 3% 5%;
}

.dissertationConclusionFirstThreeQuestionSection {
    margin: 5% 5% 1% 5%;
    padding: 0 0%;
}

.dissertationConclusionHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2, .dissertationConclusionFaqSection h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.dissertationConclusionHeadingAndParaSection p, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara p, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
}

.dissertationConclusionHeadingAndParaSection button, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button {
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 1% 5%;
}

.whatResearchMethodsCanUseForMyDissertationHeadingAndPara button {
    padding: 2% 7%;
}

.dissertationConclusionHeadingAndParaSection button a, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button a, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 17px;
    text-decoration: none;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 991px) {
    .hideThisDivSection {
        display: none;
    }
    .col-lg-5.col-10.whatResearchMethodsCanUseForMyDissertationHeadingAndPara {
        width: 100%;
    }
    .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara {
        margin-top: 5%;
    }
    .dissertationConclusionHeadingAndParaSection img {
        height: 501px !important;
    }
}
@media only screen and (max-width: 807px) {
    .dissertationConclusionInnerDivOfBannerImage {
        height: 411px;
    }
}
@media only screen and (max-width: 600px) {
    .dissertationConclusionHeadingAndParaSection img {
        height: 351px !important;
    }
}
@media only screen and (max-width: 511px) {
    .dissertationConclusionInnerDivOfBannerImage {
        height: 349px;
    }
}
@media only screen and (max-width: 460px) {
    .commonComponentFaqSection h2 {
        font-size: 23px;
    }
}
@media only screen and (max-width: 425px) {
    .dissertationConclusionHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 23px;
    }
    .dissertationConclusionHeadingAndParaSection p, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara p, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara p {
        font-size: 15px;
    }
    .dissertationConclusionHeadingAndParaSection button, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button {
        padding: 3% 7%;
    }
    .dissertationConclusionHeadingAndParaSection button a, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button a, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button a {
        font-size: 15px;
    }
}
@media only screen and (max-width: 375px) {
    .dissertationConclusionInnerDivOfBannerImage {
        height: auto;
    }
}
@media only screen and (max-width: 320px) {
    .dissertationConclusionHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 21px;
    }
}


/* CSS of Banner Form Start Here */

.dissertationConclusionQuickQueryForm {
    width: 679px;
    height: auto;
    background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1.5%;
    position: relative;
    left: 25%;
    top: 201px;
  }
  
  .dissertationConclusionQuickQueryForm .conclusionEmail, .dissertationConclusionQuickQueryForm .dissertationConclusionFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 209px;
    padding: 2px;
    background: transparent;
  }
  
  .dissertationConclusionFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }
  
    .dissertationConclusionQuickQueryForm .conclusionEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }
  
  .dissertationConclusionQuickQueryForm .dissertationConclusionFormSelect {
    padding: 2px 0;
  }
  
  .dissertationConclusionQuickQueryForm .dissertationConclusionFormSelect option {
    color: #006CA2;
    font-weight: 500;
  }
  
  .dissertationConclusionSubmitButtonDiv {
    position: relative;
    top: 21px;
    display: flex;
    justify-content: center;
  }
  
  .dissertationConclusionSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background-color: #006CA2;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 1% 2%;
    border-radius: 4px;
    margin-bottom: 4%;
  }
  
  .displayConclusion {
    display: none;
  }

  /* * {
    border: 1px solid orange;
  } */

  /* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
    .dissertationConclusionInnerDivOfBannerImage {
        height: 403px;
    }
    .dissertationConclusionQuickQueryForm {
        top: 137px;
    }
  }
@media only screen and (max-width: 1300px) {
    .dissertationConclusionQuickQueryForm {
        top: 141px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dissertationConclusionQuickQueryForm {
        left: 17%;
        top: 151px;
    }
  }
  @media only screen and (max-width: 1050px) {
    .dissertationConclusionQuickQueryForm {
    left: 10%;
    top: 173px;
    }
}
  @media only screen and (max-width: 991px) {
    .dissertationConclusionQuickQueryForm {
        left: -39%;
        top: 177px;
    }
  }
  @media only screen and (max-width: 900px) {
    .dissertationConclusionQuickQueryForm {
        left: -42%;
        top: 193px;
    }
  }
  @media only screen and (max-width: 850px) {
    .dissertationConclusionQuickQueryForm {
      left: -43%;
  }
  }
  @media only screen and (max-width: 800px) {
    .dissertationConclusionQuickQueryForm {
      left: -45%;
  }
  }
  @media only screen and (max-width: 767px) {
    .dissertationConclusionBannerImageHeadingAndForm {
        padding: 0% 0%;
    }
    .dissertationConclusionQuickQueryForm {
      width: 525px;
      left: 7%;
      top: 113px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .conclusionEmail.ms-2 {
      margin-left: 0rem !important;
    }
    .dissertationConclusionQuickQueryForm .conclusionEmail, .dissertationConclusionQuickQueryForm .dissertationConclusionFormSelect {
      width: 90%;
      margin: 6px 0;
  }
  .dissertationConclusionSubmitButtonDiv {
    top: 14px;
  }
  .dissertationConclusionSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-size: 16px;
    line-height: 23px;
    padding: 3% 0%;
    margin-bottom: 17%;
    width: 160px;
  }
  .dissertationConclusionSubmitButtonDiv {
  margin-top: 5px;
  }
  }
  @media only screen and (max-width: 700px) {
    .dissertationConclusionQuickQueryForm {
      left: 4.5%;
  }
  }
  @media only screen and (max-width: 600px) {
    .dissertationConclusionQuickQueryForm {
        left: 3%;
        top: 121px;
    }
  }
  @media only screen and (max-width: 575px) {
    .dissertationConclusionBannerImageHeadingAndForm {
        padding: 0% 7%;
    }
    .dissertationConclusionQuickQueryForm {
      left: 0%;
      width: 100%;
  }
  }