
.first-subject-heading-and-para-section {
    padding: 2rem 3rem;
}

.subject-heading-para-div {
    padding-right: 1rem;
}

.subject-heading-para-div h2 {
    color: #006ca2;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: .1em;
    line-height: 46px;
    text-align: center;
    text-transform: capitalize;
    margin: 1rem 0 2rem 0;
}

.subject-heading-para-div p {
    color: #006ca2;
    font-size: 18px;
    font-weight: 300;
    line-height: 33px;
    text-align: justify;
    margin-bottom: 0.1rem;
}

.subject-image-div img {
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 270px;
    object-fit: cover;
    object-position: center;
}

.hide-content {
    display: none;
}

.read-more-btn {
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    border: none;
    margin: 1rem 0;
    color: #FFFFFF;
    font-weight: 500;
    padding: 0.3rem 2rem;
    font-size: 19px;
}

.subject-heading-para-div p a {
    color: #006ca2;
    font-weight: 500;
    text-decoration: none;
}

/* Responsive CSS Start Here */
@media only screen and (max-width: 1100px) {
    .subject-image-div img {
        height: 450px;
    }
}
@media only screen and (max-width: 991px) {
    .subject-image-div img {
        height: 525px;
    }
}
@media only screen and (max-width: 767px) {
    .subject-image-div img {
        height: 300px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
@media only screen and (max-width: 767px) {
    .first-subject-heading-and-para-section {
        padding: 2rem 1rem;
    }
    .subject-heading-para-div {
        padding-right: 0rem;
    }
}
@media only screen and (max-width: 425px) {
    .more-subjects-btn {
        padding: 0.7rem 5rem !important;
    }
    .subject-heading-para-div h2 {
        font-size: 27px;
    }
}