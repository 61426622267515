

.dissertationSubjectPageBannerImageSection {
    padding: 5% 0 7% 0;
}

.dissertationSubjectPageHeadingAndParaInBannerSection {
    padding: 0 3%;
}

.dissertationSubjectPageHeadingAndParaInBannerSection h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 52px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(0, 108, 162, 0.75);
}

.dissertationSubjectPageHeadingAndParaInBannerSection p {
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 35px;
    color: #006CA2;
}

.individualSubjectDiv .ParaOfSubjectName {
    text-decoration: none;
    color: #000;
}

.individualSubjectDiv .ParaOfSubjectName:hover {
    color: rgba(0, 108, 162, 0.75);
}

.dissertationSubjectPageHeadingAndParaInBannerSection p img {
    width: 21px;
}

.dissertationSubjectPageHeadingAndParaInBannerSection p .dissertationSubjectStarMark {
    color: red;
    font-weight: 500;
}

.dissertationSubjectPageHeadingAndParaInBannerSection .dissertationSubjectPageOrderNowButton {
    margin-top: 7%;
    padding: 1% 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3389B5;
    border-radius: 4px;
    outline: none;
    border: none;
}

.dissertationSubjectPageHeadingAndParaInBannerSection .dissertationSubjectPageOrderNowButton a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 19px;
    line-height: 35px;
}

.topSubjectsUnderDissertationSectionStartHere {
    background: #F5F5F5;
    padding: 5% 7%;
}

.topDissertationSubjectsHeading h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: rgba(0, 108, 162, 0.75);
    margin-bottom: 5%;
}

.firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
    margin: 0 1% 3% 1%;
}

.firstSubject .firstImage {
    background-image: url('../Image/Dissertation_Subject_Page/Accounts.png');
}

.secondSubject .secondImage {
    background-image: url('../Image/Dissertation_Subject_Page/Economics.png');
}

.thirdSubject .thirdImage {
    background-image: url('../Image/Dissertation_Subject_Page/Finance.png');
}

.fourthSubject .fourthImage {
    background-image: url('../Image/Dissertation_Subject_Page/History.png');
}

.fifthSubject .fifthImage {
    background-image: url('../Image/Dissertation_Subject_Page/English.png');
}

.sixthSubject .sixthImage {
    background-image: url('../Image/Dissertation_Subject_Page/Mathematics.png');
}

.seventhSubject .seventhImage {
    background-image: url('../Image/Dissertation_Subject_Page/IT.png');
}

.eighthSubject .eighthImage {
    background-image: url('../Image/Dissertation_Subject_Page/Psychology.png');
}

.ninthSubject .ninthImage {
    background-image: url('../Image/Dissertation_Subject_Page/PS.png');
}

.tenthSubject .tenthImage {
    background-image: url('../Image/Dissertation_Subject_Page/Sociology.png');
}

.firstSubject .firstImage, .secondSubject .secondImage, .thirdSubject .thirdImage, .fourthSubject .fourthImage, .fifthSubject .fifthImage, .sixthSubject .sixthImage, .seventhSubject .seventhImage, .eighthSubject .eighthImage, .ninthSubject .ninthImage, .tenthSubject .tenthImage {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 65px;
    height: 65px;
    margin-bottom: 7%;
    margin-top: 7%;
}

/* Responsive Page Start Here */ 

@media only screen and (max-width: 991px) {
    .dissertationSubjectPageBannerImageSection {
        padding: 5% 0 0% 0;
    }
    .dissertationSubjectPageHeadingAndParaInBannerSection {
        padding: 0 10%;
    }
    .dissertationSubjectPageHeadingAndParaInBannerSection .dissertationSubjectPageOrderNowButton {
        margin-top: 2%;
        padding: 0.7% 3%;
    }
    .dissertationSubjectPageLeftImageSection {
        width: 851px;
        margin-top: 5%;
    }
    .dissertationSubjectPageLeftImageSection img {
        width: 100%;
    }
    .firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
        margin: 0 2% 3% 2%;
        width: 20%;
    }
}

@media only screen and (max-width: 767px) {
    .firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
        width: 27%;
    }
}

@media only screen and (max-width: 573px) {
    .dissertationSubjectPageHeadingAndParaInBannerSection h1 {
        font-size: 27px;
    }
    .dissertationSubjectPageHeadingAndParaInBannerSection p {
        font-size: 17px;
    }
    .dissertationSubjectPageHeadingAndParaInBannerSection .dissertationSubjectPageOrderNowButton a {
        font-size: 17px;
    }
    .topDissertationSubjectsHeading h2 {
        font-size: 27px;
    }
    .firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
        width: 35%;
    }
}

@media only screen and (max-width: 400px) {
    .firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
        width: 41%;
    }
}

@media only screen and (max-width: 325px) {
    .firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
        width: 46%;
    }
}

@media only screen and (max-width: 284px) {
    .firstSubject, .secondSubject, .thirdSubject, .fourthSubject, .fifthSubject, .sixthSubject, .seventhSubject, .eighthSubject, .ninthSubject, .tenthSubject {
        width: 75%;
    }
}