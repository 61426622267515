/* * {
    border: 1px solid orange;
} */

.row.thankYouPageRow {
    --bs-gutter-x: 0rem !important;
}

.thankYouPageBackgroundImageSectionStartHere {
    max-height: 500px;
}

.thankYouPageBackgroundImageSectionStartHere .thankYouPageBackgroundImageMainHeading {
    background-image: url('../Image/ThankYou_Page/thankYou.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 451px;
}

.thankYouPageBackgroundImageMainHeading h2 {
    text-align: center;
    margin-top: 5%;
    font-style: normal;
    font-weight: 600;
    font-size: 41px;
    line-height: 69px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
}

.thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImagePara, .thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImageButton {
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 35px;
    color: #fff;
    text-align: center;
}

.thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImageButton {
    border: 2px solid #fff;
    text-align: center;
    padding: 0.8% 5%;
}

.goToHomePageBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.goToHomePageBox a {
    text-decoration: none;
    color: #fff;
}

.goToHomePageBox img {
    width: 27px;
    position: absolute;
}

.thankYouPageBackgroundImageLastBorder {
    width: 100%;
    height: 49px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #006CA2;
}

.thankYouPageTestimonialSection {
    padding: 5% 7%;
}

.testimonialSectionHeading {
    text-align: center;
    padding: 0 0 5% 0;
}

.testimonialSectionHeading h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 52px;
    letter-spacing: 0.05em;
    color: rgba(0, 108, 162, 0.75);
}

.testimonialSectionCard {
    border-top: 9px solid #3389B5;
    background: #fff;
    box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.25);
    margin: 0 1%;
    padding: 1% 2%;
    border-radius: 0 0 13px 0;
}

.testimonialSectionCard p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 29px;
    color: #006CA2;
}

/* Responsiveness Start Here  */

@media only screen and (max-width: 767px) {
    .testimonialSectionCard {
        margin: 3% 0;
        border-radius: 0 0 13px 13px;
        width: 351px;
    }
    .thankYouPageTestimonialSection {
        width: 100%;
    }
}
@media only screen and (max-width: 629px) {
    .thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImagePara, .thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImageButton {
        font-size: 19px;
    }
    
    .thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImageButton {
        padding: 0.8% 9%;
    }
}
@media only screen and (max-width: 357px) {
    .thankYouPageBackgroundImageMainHeading .thankYouPageBackgroundImageButton {
        padding: 0.8% 13%;
    }
    .thankYouPageTestimonialSection {
        width: 100%;
    }
    .testimonialSectionHeading h2 {
        font-size: 23px;
    }
}