.costOfDissertationContainer {
    background-color: #fff;
  }
  
  .costOfDissertationHeadingPart h2 {
    color: #006ca2;
    font-size: 27px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.1em;
    margin-top: 5%;
  }
  
  .costOfDissertationBackgroundImageContainer {
    background-image: url("../../Image/Landing_Page/cost-of-dissertation.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .costOfDissertationBackgroundImageInnerContainer {
    padding: 0.5rem 0 1.5rem 0;
}
  
  .costOfDissertationCalculationPart {
    padding: 3.5%;
    width: 500px;
    height: auto;
    background: linear-gradient(
      112.98deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
  }
  
  .costOfDissertationCalculationPartDiv p,
  .costOfDissertationQuickQueryFormPart p,
  .costOfDissertationCalculationPartDiv span {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #006ca2;
  }
  
  .costOfDissertationCalculationPartHeading, .costOfDissertationQuickQueryFormPartHeading {
    font-weight: 500 !important;
    color: #006ca2 !important;
    font-size: 18px !important;
    text-align: center;
  }
  
  .costOfDissertationCalculationPartButton {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    background-color: rgba(0, 108, 162, 0.8);
    border: none;
    padding: 2% 5%;
    border-radius: 3px;
  }
  
  .costOfDissertationCalculationPartDiv .wordsOrPages {
    width: 250px;
    outline: none;
    border: 0.5px solid #006ca2;
    border-radius: 5px;
    padding: 1.5%;
    font-size: 17px;
  }
  
  .costOfDissertationCalculationPartDiv input[type="radio"] {
    color: #006ca2 !important;
    cursor: pointer;
  }
  
  .costOfDissertationCalculationPartDiv label {
    color: #006ca2;
  }
  
  .costOfDissertationCalculationPartDiv .dissertationPrice {
    font-size: 17px !important;
    border: none;
    outline: none;
    border-bottom: 1px solid #006ca2;
    width: 200px;
  }
  
  .costOfDissertationCalculationPartDiv .costOfDissertation {
    color: red;
    font-weight: 500;
    font-size: 23px;
  }
  
  /* Responsive Dissertation Cost  */
  
  @media only screen and (max-width: 767px) {
    .costOfDissertationHeadingPart h2 {
      font-size: 22px;
      line-height: 35px;
  }
  }
  
  @media only screen and (max-width: 575px) {
    .costOfDissertationBackgroundImageInnerContainer {
      --bs-gutter-x: 0rem !important;
    }
    .costOfDissertationBackgroundImageContainer {
      padding: 2% 5%;
    }
    .costOfDissertationQuickQueryFormPart {
      width: 100%;
    }
    .costOfDissertationCalculationPart {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 465px) {
    .costOfDissertationCalculationPartDiv p,
    .costOfDissertationQuickQueryFormPart p,
    .costOfDissertationCalculationPartDiv span {
      font-size: 14px;
    }
    .costOfDissertationCalculationPartDiv label {
      font-size: 14px !important;
    }
    .costOfDissertationCalculationPartButton {
      font-size: 12px;
    }
    .costOfDissertationCalculationPartDiv .wordsOrPages {
      width: 201px;
  }
  }
  @media only screen and (max-width: 425px) {
    .costOfDissertationHeadingPart h2 {
      font-size: 20px;
      line-height: 35px;
  }
  }
  
  @media only screen and (max-width: 377px) {
    .costOfDissertationCalculationPartDiv .wordsOrPages {
      width: 151px;
  }
  }
  @media only screen and (max-width: 240px) {
    .costOfDissertationCalculationPartDiv .wordsOrPages {
      width: 101px;
  }
  }
  