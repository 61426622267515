

.dissertationAbstractBannerImage {
    background-image: url('../Image/Dissertation_Abstract/Dissertation_Image.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    padding: 5% 9%;
    height: 475px;
}

.abstractBannerImageHeadingAndParaSection h1 {
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    font-size: 35px;
    line-height: 69px;
    text-align: justify;
}

.abstractBannerImageHeadingAndParaSection p {
    position: relative;
    left: 10%;
    font-weight: 500;
    font-size: 21px;
    line-height: 27px;
    color: #FFFFFF;
    /* text-align: center; */
}

.abstractBannerImageHeadingAndParaSection p span {
    color: red;
}

.dissertationAbstarctFirstThreeQuestionSection {
    padding: 0 7%;
}

.dissertationAbstractFirstQuestion h2, .dissertationAbstractSecondQuestion h2, .dissertationAbstractThirdQuestion h2, .dissertationAbstractFourthFifthSixthAndFaqSection h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.dissertationAbstractFirstQuestion h2 {
    padding-top: 7%;
}

.dissertationAbstractFourthFifthSixthAndFaqSection h2 {
    font-size: 21px;
}

.dissertationAbstractFirstQuestion p, .dissertationAbstractSecondQuestion p, .dissertationAbstractThirdQuestion p, .dissertationAbstractFourthFifthSixthAndFaqSection p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    margin: 2% 0 6% 0;
    color: #006CA2;
    text-align: justify;
}

.dissertationAbstractFAQSection p {
    margin: 2% 0 0% 0;
}

.dissertationAbstractContactOurFriendlyExpertSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dissertationAbstractContactOurFriendlyExpertSection p {
    background-color: #3389B5;
    color: #fff;
    padding: 1% 9%;
    font-style: italic;
    font-weight: 400;
    font-size: 25px;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 6%;
    border-left: 19px solid #FFE02B;
}

.dissertationAbstractContactOurFriendlyExpertSection .ContactOurFriendlyExpertNavlinkButton {
    color: #FFFFFF;
    text-decoration: none;
}

.dissertationAbstractContactOurFriendlyExpertSection img {
    width: 29px;
    margin-right: 7px;
    margin-top: -5px;
}

.dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractGetDissertationHelpNow {
    margin: 5% 0;
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    padding: 2.5% 8%;
    font-style: normal;
    font-size: 20px;
    line-height: 29px;
    outline: none;
    border: none;
}

.dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractGetDissertationHelpNow a {
    text-decoration: none;
    color: #fff;
}

.dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractReadMoreOnFaqSection {
    margin: 1% 0;
}

.dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractReadMoreOnFaqSection span {
    border-bottom: 2px solid #FFE02B;
}

.dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractReadMoreOnFaqSection a {
    text-decoration: none;
    color: #006CA2;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.02em;
}

/* FAQ Section Start Here  */

.accordion {
    --bs-accordion-btn-padding-x: 0.25rem !important;
    --bs-accordion-btn-padding-y: 1rem !important;
}

.accordion-item h2 button {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #3389B5;
}

.accordion-item .accordion-body {
    font-size: 17px;
    color: #3389B5;
    line-height: 31px;
}

.dissertationAbstractWritingServicesAndChapters {
    padding: 0 2%;
}

.dissertationAbstractWritingServicesAndChapters h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.1em;
    color: #3389B5;
    margin-bottom: 5%;
}

.dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters {
    background: #FFFFFF;
    border-top: 5px solid #3389B5;
    border-left: 1px solid #3389B5;
    border-bottom: 1px solid #3389B5;
    border-right: 1px solid #3389B5;
    border-radius: 10px 10px;
}

.dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices li, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters li {
    list-style: none;
    margin: 3% 1%;
    padding: 2% 0;
}

.dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices li img, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters li img {
    width: 15px;
}

.dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices li a, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters li a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    text-decoration-line: underline;
    color: #3389B5;
}

.dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices li a .dissertationPara {
    position: relative;
    left: 0%;
}

/* Responsive Page Start Here */


@media only screen and (max-width: 1250px) {
    .dissertationAbstractLastThreeQuestionAndFaqSection {
        padding: 0 7%;
    }
}
@media only screen and (max-width: 1199px) {
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 33px;
    }
    .abstractBannerImageHeadingAndParaSection p {
        font-size: 21px;
        left: 0%;
    }
    .dissertationAbstractWritingServicesAndChapters h4 {
        font-size: 16px;
        margin-bottom: 2%;
    }
    .dissertationAbstractBannerImage {
        padding: 4%;
        height: 501px;
    }
    .dissertationAbstractWritingServicesAndChapters {
        padding: 0px 5%;
    }
}
@media only screen and (max-width: 1100px) {
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters {
        width: 335px;
    }
}
@media only screen and (max-width: 991px) {
    .dissertationAbstractFourthFifthSixthAndFaqSection p {
        margin: 2% 0 4% 0;
    }
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 29px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        padding: 1% 5%;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractGetDissertationHelpNow {
        margin: 2% 0 7% 0;
        padding: 2.5% 8%;
    }
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices li a .dissertationPara {
        position: relative;
        left: 0;
    }
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters {
        width: 401px;
    }
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractLeftSideOfGirlImage {
        width: 100%;
        height: 501px !important;
    }
    .dissertationAbstractWritingServicesAndChapters {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 0%;
    }
    .dissertationAbstractWritingServicesAndChapters img {
        margin-bottom: 0% !important;
    }
}
@media only screen and (max-width: 873px) {
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 19px;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection {
        padding: 0 7%;
    }
}
@media only screen and (max-width: 822px) {
    .dissertationAbstractBannerImage {
        height: 411px;
    }
    .abstractBannerImageHeadingAndParaSection p {
        font-size: 19px;
    }
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 25px;
        line-height: 51px;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection {
        padding: 0 2%;
    }
}
@media only screen and (max-width: 767px) {
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractLeftSideOfGirlImage {
        height: 401px !important;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection {
        padding: 0 0%;
    }
    .dissertationAbstractWritingServicesAndChapters h4 {
        margin-top: 5%;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractGetDissertationHelpNow {
        font-size: 15px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection img {
        width: 17px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 13px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 13px;
        line-height: 35px;
        letter-spacing: 0em;
        border-left: 7px solid #FFE02B;
    }
}

@media only screen and (max-width: 682px) {
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 27px;
    }
    .abstractBannerImageHeadingAndParaSection p {
        font-size: 19px;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection {
        padding: 0 5%;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 600px) {
    .abstractBannerImageHeadingAndParaSection p {
        line-height: 21px;
    }
}
@media only screen and (max-width: 575px) {
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 25px;
    }
    .dissertationAbstractFirstQuestion h2, .dissertationAbstractSecondQuestion h2, .dissertationAbstractThirdQuestion h2, .dissertationAbstractFourthFifthSixthAndFaqSection h2 {
        font-size: 23px;
        font-weight: 500;
    }
    .dissertationAbstractFirstQuestion p, .dissertationAbstractSecondQuestion p, .dissertationAbstractThirdQuestion p, .dissertationAbstractFourthFifthSixthAndFaqSection p {
        font-size: 15px;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection, .dissertationAbstractWritingServicesAndChapters {
        padding: 0 3%;
    }
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractLeftSideOfGirlImage {
        height: 301px !important;
    }
}
@media only screen and (max-width: 574px) {
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices {
        width: 333px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 13px;
    }
}
@media only screen and (max-width: 463px) {
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 13px;
    }
}

@media only screen and (max-width: 425px) {
    .dissertationAbstractBannerImage {
        height: auto;
    }
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 21px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        padding: 1% 5%;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        font-size: 13px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection img {
        width: 19px;
        margin-right: -7px;
    }
    .dissertationAbstractFirstQuestion h2, .dissertationAbstractSecondQuestion h2, .dissertationAbstractThirdQuestion h2, .dissertationAbstractFourthFifthSixthAndFaqSection h2 {
        font-size: 19px;
    }
    .dissertationAbstractContactOurFriendlyExpertSection p {
        line-height: 46px;
        border-left: 7px solid #FFE02B;
        line-height: 37px;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractGetDissertationHelpNow {
        font-size: 15px;
    }
}
@media only screen and (max-width: 395px) {
    .dissertationAbstractContactOurFriendlyExpertSection p {
        padding: 1% 2%;
    }
}
@media only screen and (max-width: 375px) {
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 19px;
    }
    .abstractBannerImageHeadingAndParaSection p {
        font-size: 17px;
    }
    .dissertationAbstractWritingServicesAndChapters h4 {
        margin-top: 5%;
    }
    .dissertationAbstractWritingServicesAndChapters {
        padding: 0px 5%;
    }
    .dissertationAbstractFourthFifthSixthAndFaqSection .dissertationAbstractGetDissertationHelpNow {
        font-size: 14px;
    }
    .accordion-item h2 button {
        font-size: 14px;
        line-height: 20px;
    }
    .dissertationAbstractWritingServicesAndChapters {
        padding: 0px 0%;
    }
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters {
        width: 301px;
    }
    .dissertationAbstractWritingServicesAndChapters .dissertationAbstractWritingServices li a, .dissertationAbstractWritingServicesAndChapters .dissertationAbstractChapters li a {
        font-size: 14px;
        line-height: 25px;
    }
}
@media only screen and (max-width: 320px) {
    .abstractBannerImageHeadingAndParaSection h1 {
        font-size: 16px;
    }
    .abstractBannerImageHeadingAndParaSection p {
        font-size: 15px;
        line-height: 14px;
    }
}


/* CSS of Banner Form Start Here */

.dissertationAbstractQuickQueryForm {
    width: 685px;
    height: auto;
    background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1.5%;
    position: relative;
    /* left: 3%; */
    top: 143px;
    margin: 0 auto;
  }
  /* * {
    border: 1px solid orange;
  } */
  .dissertationAbstractQuickQueryForm .abstractEmail, .dissertationAbstractQuickQueryForm .dissertationAbstractFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 209px;
    padding: 2px;
    background: transparent;
  }
  
  .dissertationAbstractFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }
  
    .dissertationAbstractQuickQueryForm .abstractEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }
  
  .dissertationAbstractQuickQueryForm .dissertationAbstractFormSelect {
    padding: 2px 0;
  }
  
  .dissertationAbstractQuickQueryForm .dissertationAbstractFormSelect option {
    color: #006CA2;
    font-weight: 500;
  }
  
  .dissertationAbstractSubmitButtonDiv {
    position: relative;
    top: 21px;
    display: flex;
    justify-content: center;
  }
  
  .dissertationAbstractSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background-color: #006CA2;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 1% 2%;
    border-radius: 4px;
    margin-bottom: 4%;
  }
  
  .displayAbstract {
    display: none;
  }

  /* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
    .dissertationAbstractBannerImage {
        height: 401px;
    }
    .dissertationAbstractQuickQueryForm {
        top: 69px;
    }
  }
@media only screen and (max-width: 1300px) {
    .dissertationAbstractQuickQueryForm {
        top: 49px;
    }
  }
@media only screen and (max-width: 1200px) {
    .dissertationAbstractQuickQueryForm {
        top: 79px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dissertationAbstractQuickQueryForm {
        top: 89px;
    }
  }
  @media only screen and (max-width: 991px) {
    .dissertationAbstractQuickQueryForm {
        top: 103px;
    }
  }
  @media only screen and (max-width: 767px) {
    .dissertationAbstractQuickQueryForm {
      width: 525px;
      top: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .abstractEmail.ms-2 {
      margin-left: 0rem !important;
    }
    .dissertationAbstractQuickQueryForm .abstractEmail, .dissertationAbstractQuickQueryForm .dissertationAbstractFormSelect {
      width: 90%;
      margin: 6px 0;
  }
  .dissertationAbstractSubmitButtonDiv {
    top: 14px;
  }
  .dissertationAbstractSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-size: 16px;
    line-height: 23px;
    padding: 3% 0%;
    margin-bottom: 17%;
    width: 160px;
  }
  .dissertationAbstractSubmitButtonDiv {
  margin-top: 5px;
  }
  }
  @media only screen and (max-width: 600px) {
    .dissertationAbstractQuickQueryForm {
        top: 49px;
    }
  }
  @media only screen and (max-width: 575px) {
    .dissertationAbstractQuickQueryForm {
      left: 0%;
      width: 100%;
  }
  }