header {
    background: #FFFFFF;
    width: 100%;
    height: 129px;
}

/* Upper Navbar CSS */
.upper-nav-div ul {
    list-style: none;
    list-style: none;
    display: inline-flex;
    justify-content: flex-end;
    background: rgba(0, 108, 162, 0.8);
    align-items: center;
    padding-left: 0rem;
    margin-bottom: 0rem;
    float: right;
    border-radius: 0 0 0 45px;
}

.upper-nav-div ul li {
    margin: 0 33px;
    padding: 0.7rem 0.7rem;
    color: #FFFFFF;
    font-family: 400;
    text-decoration: none;
}

.upper-nav-div ul li .active {
    border-bottom: 2px solid #FFFFFF;
}

.upper-nav-div ul li a {
    text-decoration: none;
    color: #FFFFFF;
    font-family: 400;
}

/* Below Navbar CSS */
.toggle-icon span i {
    position: absolute;
    right: 3rem;
    font-size: 24px;
    top: 1.5rem;
    display: none;
}

.below-navbar {
    /* position: absolute; */
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.below-navbar-image-div a {
    text-decoration: none;
}

.below-navbar-image-div img {
    position: absolute;
    top: -0.5rem;
    left: 3rem;
    width: 299px;
}

.below-nav-menu > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0rem;
    margin-bottom: 0rem;
}

.below-nav-menu > ul > li {
    margin: 0 33px;
    padding: 0.7rem 0.4rem;
    font-weight: 400;
    position: relative;
}

.below-nav-menu ul li .active {
    border-bottom: 2px solid #006CA2;
}

.below-nav-menu > ul > li > a {
    text-decoration: none;
    color: #006CA2;
}

.getQuoteListItem .getQuoteBtn {
    font-weight: 400;
    border: none;
    outline: none;
    text-align: center;
    text-transform: uppercase;
    background-color: #006CA2;
    padding: 0.35rem 1rem;
    border-radius: 3px;
}

.getQuoteListItem .getQuoteBtn a {
    text-decoration: none;
    color: #FFFFFF;
}

.sub-nav-menu {
    position: absolute;
    left: 0;
    top: 100%;
    list-style: none;
    width: 23rem;
    background: #FFFFFF;
    box-shadow: 0px 9px 17px rgba(109, 109, 109, 0.23);
    border-radius: 0.25rem;
    padding-left: 1.5rem;
    margin-bottom: 0rem;
    z-index: 999;
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;
}

.sub-nav-menu li {
    margin: 0.8rem 0 0 0;
    width: 100%;
}

.sub-nav-menu li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #006CA2;
    font-weight: 600;
}

.below-nav-menu > ul > li:hover > ul {
    height: 275px;
}

/* Responsive Page CSS Start Here */
@media only screen and (max-width: 1250px) {
    .upper-nav-div ul li {
        margin: 0 29px;
    }
    .below-navbar-image-div img {
        left: 1.5rem;
    }
    .below-nav-menu > ul > li {
        margin: 0 27px;
    }
}
@media only screen and (max-width: 1199px) {
    .upper-nav-div ul li {
        margin: 0 24px;
    }
    .below-navbar-image-div img {
        top: -0.1rem;
        left: 2.5rem;
        width: 275px;
    }
    .below-nav-menu > ul > li {
        margin: 0 21px;
    }
}
@media only screen and (max-width: 1150px) {
    .upper-nav-div ul li {
        margin: 0 21px;
    }
    .below-nav-menu > ul > li {
        margin: 0 18px;
        padding: 0.5rem 0.4rem;
    }
}
@media only screen and (max-width: 1100px) {
    .below-navbar-image-div img {
        left: 2rem;
        width: 249px;
    }
    .below-nav-menu > ul > li {
        padding: 0.3rem 0.4rem;
    }
}
@media only screen and (max-width: 1050px) {
    header {
        height: 115px;
    }
    .upper-nav-div ul li {
        margin: 0px 21px;
    }
    .upper-nav-div ul li a {
        font-size: 14px;
    }
    .below-navbar-image-div img {
        width: 225px;
        top: 0.5rem;
    }
    .below-nav-menu > ul > li {
        font-size: 14px;
    }
    .getQuoteListItem .getQuoteBtn a {
        font-size: 13px;
    }
    .below-nav-menu > ul > li:hover > ul {
        height: 255px;
    }
    .sub-nav-menu {
        width: 20rem;
    }
}
@media only screen and (max-width: 991px) {
    .upper-nav-div ul li {
        margin: 0px 18px;
    }
    .upper-nav-div ul li a {
        font-size: 13px;
    }
    .below-nav-menu > ul > li {
        font-size: 13px;
        margin: 0 15px;
    }
    .getQuoteListItem .getQuoteBtn a {
        font-size: 12px;
    }
    .sub-nav-menu {
        width: 17.5rem;
    }
    .below-nav-menu > ul > li:hover > ul {
        height: 245px;
    }
}
@media only screen and (max-width: 925px) {
    header {
        height: auto;
        padding: 1rem 0 0rem 0;
    }
    .upper-nav-div ul {
        display: none;
    }
    .toggle-icon span i {
        display: block;
    }
    .below-navbar {
        display: inline-block;
    }
    .below-nav-menu {
        margin-top: -1.5rem;
    }
    .below-nav-menu > ul {
        display: inline-block;
        padding-left: 1rem;
        margin-top: 1rem;
    }
    .below-navbar-image-div img {
        position: relative;
        top: -0.9rem;
        width: 201px;
    }
    .below-nav-menu > ul > li {
        padding: 0.7rem 0.4rem;
        font-size: 15px;
    }
    .getQuoteListItem .getQuoteBtn a {
        font-size: 14px;
    }
    .main-nav-menu {
        height: 0;
        overflow: hidden;
        transition: all 0.3s linear;
    }
    .show-main-nav-menu {
        height: 27.5rem;
    }
    .sub-nav-menu {
        position: unset;
        width: 100%;
        background: #FFFFFF;
        box-shadow: none;
        border-radius: 0;
        padding-left: 1.8rem;
        z-index: 0;
        height: unset;
    }
    .below-nav-menu > ul > li:hover > ul {
        height: unset;
    }
    .sub-nav-menu li a {
        font-weight: 400;
    }
}
@media only screen and (max-width: 425px) {
    .below-navbar-image-div img {
        left: 1rem;
        width: 169px;
    }
    .below-nav-menu > ul {
        padding-left: 0rem;
    }
    .sub-nav-menu {
        padding-left: 1rem;
    }
    .below-nav-menu > ul > li {
        font-size: 13px;
    }
    .sub-nav-menu li a {
        font-size: 13px;
    }
    .show-main-nav-menu {
        height: 25.5rem;
    }
    .toggle-icon span i {
        top: 1.2rem;
        font-size: 21px;
    }
}