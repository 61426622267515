
.dissertationMethodologyInnerDivOfBannerImage {
    background-image: url('../Image/Dissertation_Methodology/33.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 475px;
}

.dissertationMethodologyFirstThreeQuestionSection {
    margin: 5% 5%;
    padding: 0 0%;
}

.dissertationMethodologyHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.dissertationMethodologyHeadingAndParaSection p, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara p, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
}

.dissertationMethodologyHeadingAndParaSection ol li, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara ol li {
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
    margin: 15px 0;
}

.dissertationMethodologyHeadingAndParaSection button, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button {
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 1% 5% !important;
}

.canHireAnExpertToTakeMyDissertationMethodologySection {
    padding: 0 5%;
}

.dissertationMethodologyHeadingAndParaSection button a, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button a, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 17px;
    text-decoration: none;
}

.whatResearchMethodsCanUseForMyDissertationHeadingAndPara {
    margin-top: 3%;
}

.whatResearchMethodsCanUseForMyDissertationImportantServices {
    padding: 0 7%;
}

.whatResearchMethodsCanUseForMyDissertationImportantServices ul {
    background: #FFFFFF;
    border-top: 5px solid #3389B5;
    border-left: 1px solid #3389B5;
    border-bottom: 1px solid #3389B5;
    border-right: 1px solid #3389B5;
    border-radius: 10px 10px;
    /* height: 375px; */
    width: 301px;
}

.whatResearchMethodsCanUseForMyDissertationImportantServices ul li {
    list-style: none;
    margin: 1% 1%;
    padding: 1% 0;
}

.whatResearchMethodsCanUseForMyDissertationImportantServices ul li img {
    width: 15px;
}

.whatResearchMethodsCanUseForMyDissertationImportantServices ul li a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    text-decoration-line: underline;
    color: #3389B5;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .whatResearchMethodsCanUseForMyDissertationSection {
        padding: 0 7%;
    }
}
@media only screen and (max-width: 991px) {
    .hideThisDivSection {
        display: none;
    }
    .col-lg-5.col-10.whatResearchMethodsCanUseForMyDissertationHeadingAndPara {
        width: 100%;
    }
    .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara {
        margin-top: 5%;
    }
    .whatResearchMethodsCanUseForMyDissertationImportantServices {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 3%;
    }
    .whatResearchMethodsCanUseForMyDissertationImportantServices ul {
        width: 351px;
    }
    .dmethodologyimagediv img {
        margin: auto;
        display: flex;
    }
}
@media only screen and (max-width: 807px) {
    .dissertationMethodologyInnerDivOfBannerImage {
        height: 411px;
    }
}
@media only screen and (max-width: 600px) {
    .dissertationMethodologyInnerDivOfBannerImage {
        height: 349px;
    }
}
@media only screen and (max-width: 500px) {
    .dissertationMethodologyInnerDivOfBannerImage {
        height: 301px;
    }
}
@media only screen and (max-width: 425px) {
    .dissertationMethodologyInnerDivOfBannerImage {
        padding: 0 15%;
    }
    .dissertationMethodologyHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 23px;
    }
    .dissertationMethodologyHeadingAndParaSection p, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara p, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara p {
        font-size: 15px;
    }
    .dissertationMethodologyHeadingAndParaSection button, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button {
        padding: 3% 7%;
    }
    .dissertationMethodologyHeadingAndParaSection button a, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara button a, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara button a {
        font-size: 15px;
    }
    .whatResearchMethodsCanUseForMyDissertationImportantServices ul {
        width: 301px;
    }
    .accordion-item h2 button {
        font-size: 15px;
    }
}
@media only screen and (max-width: 375px) {
    .dissertationMethodologyInnerDivOfBannerImage {
        height: 301px;
    }
}
@media only screen and (max-width: 320px) {
    .dissertationMethodologyHeadingAndParaSection h2, .whatResearchMethodsCanUseForMyDissertationHeadingAndPara h2, .canHireAnExpertToTakeMyDissertationMethodologyHeadingAndPara h2 {
        font-size: 21px;
    }
}


/* CSS of Banner Form Start Here */

.dissertationMethodologyQuickQueryForm {
    width: 679px;
    height: auto;
    background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1.5%;
    position: relative;
    /* left: 3%; */
    top: 335px;
    margin: 0 auto;
  }
  
  .dissertationMethodologyQuickQueryForm .methodologyEmail, .dissertationMethodologyQuickQueryForm .dissertationMethodologyFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 209px;
    padding: 2px;
    background: transparent;
  }
  
  .dissertationMethodologyFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }
  
    .dissertationMethodologyQuickQueryForm .methodologyEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }
  
  .dissertationMethodologyQuickQueryForm .dissertationMethodologyFormSelect {
    padding: 2px 0;
  }
  
  .dissertationMethodologyQuickQueryForm .dissertationMethodologyFormSelect option {
    color: #006CA2;
    font-weight: 500;
  }
  
  .dissertationMethodologySubmitButtonDiv {
    position: relative;
    top: 21px;
    display: flex;
    justify-content: center;
  }
  
  .dissertationMethodologySubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background-color: #006CA2;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 1% 2%;
    border-radius: 4px;
    margin-bottom: 4%;
  }
  
  .displayMethodology {
    display: none;
  }

  /* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
    .dissertationMethodologyInnerDivOfBannerImage {
        height: 403px;
    }
    .dissertationMethodologyQuickQueryForm {
        top: 265px;
    }
  }
@media only screen and (max-width: 1300px) {
    .dissertationMethodologyQuickQueryForm {
        top: 245px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dissertationMethodologyQuickQueryForm {
        left: 0%;
        top: 265px;
    }
  }
  @media only screen and (max-width: 1050px) {
    .dissertationMethodologyQuickQueryForm {
    left: -5%;
    }
}
  @media only screen and (max-width: 991px) {
    .dissertationMethodologyQuickQueryForm {
        left: -11%;
        top: 273px;
    }
  }
  @media only screen and (max-width: 900px) {
    .dissertationMethodologyQuickQueryForm {
      left: -17%;
  }
  }
  @media only screen and (max-width: 850px) {
    .dissertationMethodologyQuickQueryForm {
      left: -17%;
  }
  }
  @media only screen and (max-width: 800px) {
    .dissertationMethodologyQuickQueryForm {
      left: -23%;
  }
  }
  @media only screen and (max-width: 767px) {
    .dissertationMethodologyQuickQueryForm {
      width: 525px;
      left: 0%;
      top: 165px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .methodologyEmail.ms-2 {
      margin-left: 0rem !important;
    }
    .dissertationMethodologyQuickQueryForm .methodologyEmail, .dissertationMethodologyQuickQueryForm .dissertationMethodologyFormSelect {
      width: 90%;
      margin: 6px 0;
  }
  .dissertationMethodologySubmitButtonDiv {
    top: 14px;
  }
  .dissertationMethodologySubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-size: 16px;
    line-height: 23px;
    padding: 3% 0%;
    margin-bottom: 17%;
    width: 160px;
  }
  .dissertationMethodologySubmitButtonDiv {
  margin-top: 5px;
  }
  }
  @media only screen and (max-width: 700px) {
    .dissertationMethodologyQuickQueryForm {
      left: 0%;
  }
  }
  @media only screen and (max-width: 600px) {
    .dissertationMethodologyQuickQueryForm {
        left: 0%;
        top: 175px;
    }
  }
  @media only screen and (max-width: 575px) {
    .dissertationMethodologyBannerImageHeadingAndForm {
        padding: 0% 7%;
    }
    .dissertationMethodologyQuickQueryForm {
      left: 0%;
      width: 100%;
  }
  }