
/* First Section CSS Start Here */
.firstSectionOfDissertationWritingServicesPageStartHere {
    background-image: url("../Image/Dissertation_Writing_Services/dissertation_writing_services.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
    padding: 4rem 0 6rem 0;
}

.firstSectionHeadingDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.firstSectionHeadingDiv h1 {
    font-weight: 600;
    font-size: 31px;
    line-height: 69px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.firstSectionHeadingDiv p {
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
}

/* Second and third Section CSS Start Here */
.secondSectionOfDissertationWritingServicesHeading, .thirdSectionOfDissertationWritingServicesHeading, .fourthSectionOfDissertationWritingServicesHeading, .seventhSectionOfDissertationWritingServicesHeading {
    padding: 3rem 0 2rem 0;
}

.secondSectionOfHeadingDiv h2, .thirdSectionOfHeadingDiv h2, .fourthSectionHeadingDiv h2, .seventhSectionOfHeadingDiv h2, .fifthSectionHeadingDiv h2, .sixthSectionHeadingDiv h2 {
    text-align: center;
    font-weight: 400;
    font-size: 29px;
    line-height: 58px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #006CA2;
}

.secondSectionParaDiv p {
    position: relative;
    top: 2rem;
}

.secondSectionParaDiv p, .thirdSectionParaDiv p, .fifthSectionHeadingDiv p, .sixthSectionHeadingDiv p {
    font-weight: 300;
    font-size: 19px;
    line-height: 35px;
    text-align: justify;
    color: #006CA2;
}

.secondSectionImgDiv img, .thirdSectionImgDiv img {
    width: 465px;
}

.dissertationServicesCardMainDiv {
    margin: 0.5rem 0;
}

/* Fourth Section CSS Start Here */
.dissertationServicesCard {
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    padding: 1rem 2rem;
    margin: 1rem 0;
    background: #FFFFFF;
    height: 100% !important;
}

/* .col-lg-6.col-md-6.col-sm-6.dissertationServicesCardMainDiv {
    margin: 1.5rem 0;
} */

.fourthSectionOfDissertationWritingServicesPageStartHere a {
    text-decoration: none;
}

.dissertationServicesCard:hover {
    background-color: #006CA2;
    transition: 0.3s ease;
}

.dissertationServicesCard:hover h5, .dissertationServicesCard:hover p {
    color: #FFFFFF;
}

.dissertationServicesCard h5 {
    font-weight: 700;
    line-height: 29px;
    color: #006CA2;
}

.dissertationServicesCard .dissertationServicesPara {
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    text-align: justify;
    color: #006CA2;
}

.dissertationServicesCard .dissertationServicesLearnMoreBtn {
    font-weight: 500;
    font-size: 19px;
    line-height: 35px;
    cursor: pointer;
    color: #006CA2;
}

/* Fifth and Sixth Section CSS Start Here */
.fifthSectionOfDissertationWritingServicesHeading {
    padding: 3rem 0 1rem 0;
}

.sixthSectionOfDissertationWritingServicesHeading {
    padding: 1rem 0 0 0;
}

/* Responsive Page Start Here */
@media only screen and (max-width: 1199px) {
    .dissertationServicesCardHeightClassName {
        height: 19rem;
    }
    .dissertationServicesCard .learnMoreLink {
        margin-top: 2.65rem;
    }
}
@media only screen and (max-width: 991px) {
    .secondSectionOfDissertationWritingServicesPageStartHere, .thirdSectionOfDissertationWritingServicesPageStartHere {
        padding: 0 3rem;
    }
    .secondSectionDissertationWritingServicesPageSecondInnerDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
    .secondSectionImgDiv img, .thirdSectionImgDiv img {
       display: flex;
       margin: auto;
    }
    .thirdSectionImgDiv img {
        margin-bottom: 3rem;
    }
    .secondSectionParaDiv p {
        top: 0rem;
    }
    .dissertationServicesCardHeightClassName {
        height: unset;
    }
}
@media only screen and (max-width: 767px) {
    .dissertationServicesCard .learnMoreLink {
        margin-top: 0rem;
    }
    .firstSectionHeadingDiv h1 {
        font-size: 28px;
        line-height: 49px;
    }
}
@media only screen and (max-width: 575px) {
    .secondSectionOfDissertationWritingServicesPageStartHere, .thirdSectionOfDissertationWritingServicesPageStartHere {
        padding: 0 1rem;
    }
}
@media only screen and (max-width: 425px) {
    .secondSectionOfDissertationWritingServicesHeading, .thirdSectionOfDissertationWritingServicesHeading, .fourthSectionOfDissertationWritingServicesHeading, .seventhSectionOfDissertationWritingServicesHeading {
        padding: 2rem 0 1rem 0;
    }
    .firstSectionHeadingDiv h1 {
        font-size: 25px;
    }
    .firstSectionHeadingDiv p {
        font-size: 15px;
    }
    .secondSectionOfHeadingDiv h2, .thirdSectionOfHeadingDiv h2, .fourthSectionHeadingDiv h2, .seventhSectionOfHeadingDiv h2 {
        font-size: 23px;
        line-height: 45px;
    }
}