/* Social Statistics  */

.socialStatisticsContainer {
  background-color: #fff;
  margin-top: 3%;
}

.socialStatisticsHeadingPart h2 {
  color: #006ca2;
  font-size: 27px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
}

/* .socialStatisticsHeadingPart h2 span {
    border-bottom-color: #006ca2;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    margin: 0 0 10px;
    padding: 0 0 10px;
  } */

.socialStatisticsImagePart img {
  width: 514px;
  height: 484px;
}

.qualifiedTutors {
  position: relative;
  left: 11.5%;
  width: 176px;
  height: 111px;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  margin-bottom: 0%;
  padding: 2.5%;
}

.selectedYearlyData, .socialStatisticsDetailPartInnerDiv select option {
  color: #006ca2 !important;
  font-weight: 500;
}

.studentsEnrolled {
  position: relative;
  left: -4%;
  width: 271px;
  height: 115px;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  padding: 2.5%;
  margin-top: 4%;
}

.successRate {
  position: relative;
  width: 207px;
  height: 105px;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  padding: 2.5%;
  margin-top: -5%;
  left: -3%;
}

.completeDissertation {
  position: relative;
  left: 9%;
  width: 271px;
  height: 115px;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  padding: 2.5%;
}

.subjectsSolved {
  width: 251px;
  height: 175px;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  padding: 3.5%;
  margin-top: 3%;
}

.wordsWritten {
  position: relative;
  left: -0.5%;
  margin-top: 2.5%;
  width: 243px;
  height: 105px;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  padding: 2.5%;
}

.qualifiedTutors span i,
.studentsEnrolled span i,
.completeDissertation span i,
.wordsWritten span i,
.successRate span i,
.subjectsSolved span i {
  color: #006ca2;
  font-size: 14px;
  margin-top: -25%;
}

.qualifiedTutors .qualifiedTutorsSpan,
.completeDissertation .completeDissertationSpan,
.wordsWritten .wordsWrittenSpan,
.successRate .successRateSpan,
.subjectsSolved .subjectsSolvedSpan {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #006ca2;
}

.qualifiedTutors .qualifiedTutorsPara,
.completeDissertation .completeDissertationPara,
.wordsWritten .wordsWrittenPara,
.successRate .successRatePara {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 25px;
  color: #006ca2;
  text-align: center;
}

.subjectsSolved select {
  border: none;
  background: linear-gradient(
    299.73deg,
    #61caff 0%,
    rgba(0, 108, 162, 0) 100%,
    #ffffff 100%
  );
  color: #006ca2;
  margin-left: 3.5%;
}

.studentsEnrolled .studentsEnrolledSpan {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  color: #006ca2;
  text-align: center;
}

.studentsEnrolled .studentsEnrolledPara,
.subjectsSolved .subjectsSolvedPara {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #006ca2;
  text-align: center;
}

.subjectsSolved .subjectsSolvedPara {
  text-align: left;
}

/* Responsive Page Start Here  */

/* Social Statistics  */

@media only screen and (max-width: 1013px) {
  .completeDissertation {
    left: 6%;
  }
}

@media only screen and (max-width: 1199px) {
  .socialStatisticsThirdDiv {
    --bs-gutter-x: 1.5rem;
  }
  .qualifiedTutors {
    left: 5%;
    width: 199px;
}
.completeDissertation {
  left: 2%;
}
.subjectsSolved {
  width: 467px;
  position: relative;
  right: 6%;
}
.studentsEnrolled {
  left: -2%;
  width: 389px;
}
}

@media only screen and (max-width: 991px) {
  .socialStatisticsThirdDiv {
    --bs-gutter-x: -11.5rem;
  }
  .studentsEnrolled {
    left: 0%;
    width: 626px;
}
.subjectsSolved {
  width: 100%;
  right: -1.5%;
}
.wordsWritten {
  left: -4%;
}
}

@media only screen and (max-width: 767px) {
  .socialStatisticsThirdDiv {
    --bs-gutter-x: 1.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .socialStatisticsThirdDiv {
    --bs-gutter-x: -4.5rem;
  }
  .subjectsSolved {
    right: -3%;
    height: 201px;
}
.successRate {
  margin-top: -9%;
}
}

@media only screen and (max-width: 515px) {
  .qualifiedTutors {
    left: 0%;
    width: 176px;
    height: 100%;
  }

  .studentsEnrolled {
    left: 0%;
    width: 207px;
    height: 100%;
    margin-top: 4%;
  }

  .successRate {
    width: 100%;
    height: 100%;
    margin-top: -5%;
    left: 0%;
  }

  .completeDissertation {
    left: 0%;
    width: 207px;
    height: 100%;
  }

  .subjectsSolved {
    width: 251px;
    height: 100%;
    right: 0%;
  }
  .wordsWritten {
    left: 0%;
    width: 100%;
}
  /* .wordsWritten {
    left: 0%;
    width: 207px;
    height: 100%;
    padding: 2.5%;
  } */
  .qualifiedTutors .qualifiedTutorsSpan,
  .completeDissertation .completeDissertationSpan,
  .wordsWritten .wordsWrittenSpan,
  .successRate .successRateSpan,
  .subjectsSolved .subjectsSolvedSpan {
    font-size: 13px;
    line-height: 17px;
  }

  .qualifiedTutors .qualifiedTutorsPara,
  .completeDissertation .completeDissertationPara,
  .wordsWritten .wordsWrittenPara,
  .successRate .successRatePara {
    font-size: 17px;
    line-height: 17px;
  }
  .studentsEnrolled .studentsEnrolledSpan {
    font-size: 17px;
  }

  .studentsEnrolled .studentsEnrolledPara,
  .subjectsSolved .subjectsSolvedPara {
    font-size: 13px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 482px) {
  .socialStatisticsImagePart img {
    width: 100%;
    height: auto;
  }
  .socialStatisticsHeadingPart h2 {
    font-size: 21px;
  }
  .socialStatisticsDetailPartInnerDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .qualifiedTutors,
  .studentsEnrolled,
  .successRate,
  .completeDissertation,
  .subjectsSolved,
  .wordsWritten {
    left: 0%;
    width: 75vw;
  }
  .studentsEnrolled {
    margin-top: 3%;
    left: -6%;
  }
  .successRate {
    margin-top: 3%;
    left: -6%;
  }
  .completeDissertation {
    left: -6%;
    margin-top: 3%;
  }
  .subjectsSolved {
    height: 100%;
  }
  .wordsWritten {
    left: 0%;
    margin-top: 3%;
  }
}

@media only screen and (max-width: 375px) {
  .studentsEnrolled {
    left: -9%;
  }
  .successRate {
    left: -9%;
  }
  .completeDissertation {
    left: -9%;
  }
}

@media only screen and (max-width: 320px) {
  .studentsEnrolled {
    left: -11%;
  }
  .successRate {
    left: -11%;
  }
  .completeDissertation {
    left: -11%;
  }
}

@media only screen and (max-width: 240px) {
  .socialStatisticsHeadingPart h2 {
    font-size: 13px;
  }
  .studentsEnrolled {
    left: -17%;
  }
  .successRate {
    left: -17%;
  }
  .completeDissertation {
    left: -17%;
  }
}

/* Write My Dissertation For Me  */

.secondSectionStartHere {
  margin: 3% 0;
}

.writeMyDissertationForMe .heading-1,
.whyChooseUs .heading-2,
.howWeWork .heading-3 {
  position: relative;
  left: 17%;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 39px;
  letter-spacing: 0.08em;
  color: #006ca2;
}

.writeMyDissertationForMe {
  position: relative;
  margin-top: 4%;
}

.writeMyDissertationForMe .box-1 {
  position: absolute;
  width: 187px;
  height: 29px;
  left: -125px;
  top: 3px;
  background: linear-gradient(
    90deg,
    #006ca2 23.44%,
    rgba(0, 108, 162, 0.15) 100%
  );
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 0px 20px;
  transform: rotate(-180deg);
}

.writeMyDissertationForMe p,
.whyChooseUs p,
.howWeWork p {
  padding: 0 17%;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  color: #006ca2;
  text-align: justify;
}

.whyChooseUs .box-2 {
  position: absolute;
  width: 187px;
  height: 29px;
  right: 0;
  /* top: 365px; */
  background: linear-gradient(
    90deg,
    #006ca2 23.44%,
    rgba(0, 108, 162, 0.15) 100%
  );
  backdrop-filter: blur(4px);
  border-radius: 20px 0px 0px 0px;
  transform: rotate(-0.55deg);
}

.howWeWork .box-3 {
  position: absolute;
  width: 187px;
  height: 29px;
  left: 0;
  /* top: 721px; */
  background: linear-gradient(
    90deg,
    #006ca2 23.44%,
    rgba(0, 108, 162, 0.15) 100%
  );
  backdrop-filter: blur(4px);
  border-radius: 0px 0px 0px 20px;
  transform: rotate(-180deg);
}

/* Responsive Section Start Here  */

@media only screen and (max-width: 1199px) {
  .writeMyDissertationForMe .heading-1,
  .whyChooseUs .heading-2,
  .howWeWork .heading-3 {
    left: 21%;
    font-size: 21px;
  }
  .writeMyDissertationForMe p,
  .whyChooseUs p,
  .howWeWork p {
    padding: 0 9%;
    position: relative;
    left: 12%;
  }
}

@media only screen and (max-width: 1112px) {
  .writeMyDissertationForMe .heading-1,
  .whyChooseUs .heading-2,
  .howWeWork .heading-3 {
    left: 25%;
    font-size: 19px;
  }
  .writeMyDissertationForMe .box-1 {
    height: 23px;
  }
  .whyChooseUs .box-2 {
    height: 23px;
  }
  .howWeWork .box-3 {
    height: 23px;
  }
}

@media only screen and (max-width: 1100px) {
  .writeMyDissertationForMe p, .whyChooseUs p, .howWeWork p {
    left: 15%;
}
}

@media only screen and (max-width: 1048px) {
  .writeMyDissertationForMe .heading-1,
  .whyChooseUs .heading-2,
  .howWeWork .heading-3 {
    left: 27%;
  }
}

@media only screen and (max-width: 991px) {
  .writeMyDissertationForMe .heading-1, .whyChooseUs .heading-2, .howWeWork .heading-3 {
    left: 20%;
    font-size: 25px;
    font-weight: 500;
  }
  .writeMyDissertationForMe .box-1 {
    width: 299px;
  }
  .writeMyDissertationForMe p,
  .whyChooseUs p,
  .howWeWork p {
    padding: 0% 11%;
    left: 0%;
    font-size: 18px;
    line-height: 35px;
  }
  .whyChooseUs .box-2 {
    left: 0;
    border-radius: 0px 20px 0px 0px;
}
.image-section img {
  padding: 0 0%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media only screen and (max-width: 900px) {
  .writeMyDissertationForMe .heading-1, .whyChooseUs .heading-2, .howWeWork .heading-3 {
    left: 25%;
  }
  .writeMyDissertationForMe p,
  .whyChooseUs p,
  .howWeWork p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .writeMyDissertationForMe .heading-1, .whyChooseUs .heading-2, .howWeWork .heading-3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 700px) {
  .writeMyDissertationForMe .heading-1, .whyChooseUs .heading-2, .howWeWork .heading-3 {
    left: 31%;
}
}
@media only screen and (max-width: 699px) {
  .writeMyDissertationForMe .heading-1,
  .whyChooseUs .heading-2,
  .howWeWork .heading-3 {
    text-align: center;
  }
  .writeMyDissertationForMe .box-1 {
    width: 299px;
  }
  .writeMyDissertationForMe p,
  .whyChooseUs p,
  .howWeWork p {
    padding: 0% 11%;
  }
  .image-section img {
    padding: 0% 11%;
  }
}

@media only screen and (max-width: 600px) {
  .writeMyDissertationForMe .box-1, .howWeWork .box-3, .whyChooseUs .box-2 {
    display: none;
}
.writeMyDissertationForMe .heading-1, .whyChooseUs .heading-2, .howWeWork .heading-3 {
  left: 5%;
}
.writeMyDissertationForMe p, .whyChooseUs p, .howWeWork p {
  padding: 0% 5%;
}
}

@media only screen and (max-width: 467px) {
  .writeMyDissertationForMe .heading-1,
  .whyChooseUs .heading-2, .howWeWork .heading-3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 240px) {
  .writeMyDissertationForMe .heading-1,
  .howWeWork .heading-3 {
    left: 3%;
    font-size: 15px;
  }
  .whyChooseUs .heading-2 {
    left: 3%;
    font-size: 15px;
  }
  .writeMyDissertationForMe .box-1,
  .whyChooseUs .box-2,
  .howWeWork .box-3 {
    display: none;
  }
  .writeMyDissertationForMe p,
  .whyChooseUs p,
  .howWeWork p {
    padding: 0% 3%;
  }
  .image-section img {
    padding: 0% 3%;
  }
}

/* Do My Dissertation  */

.secondSectionInnerDiv {
  --bs-gutter-x: 1rem !important;
}

.thirdSectionContentStartHere {
  background: #006ca2;
}

/* .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 0rem;
} */

/* .image-section img {
    filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
} */

.dissertationWritingService {
  position: relative;
  left: 7%;
}

.doMyDissertation,
.capstoneProjects {
  position: relative;
  right: 7%;
}

.image-section .dissertationWritingImage {
  /* width: 599px; */
  height: 100%;
  position: relative;
  left: -12px;
  border: 2px solid #006ca2;
}

.image-section .dissertationWritingServicesPicClass {
  position: relative;
  left: 12px;
}

.doMyDissertation .heading-1,
.dissertationWritingService .heading-2,
.capstoneProjects .heading-3 {
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 39px;
  letter-spacing: 0.08em;
  color: #fff;
  text-align: left;
}

.doMyDissertation .paragraph,
.dissertationWritingService .paragraph,
.capstoneProjects .paragraph {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 29px;
  color: #fff;
  text-align: justify;
}

.doMyDissertation button,
.dissertationWritingService button,
.capstoneProjects button {
  background: #ffe02b;
  border-radius: 4px;
  border: 2px solid #ffe02b;
  width: 131px;
  height: 47px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.81);
  font-size: 17px;
  line-height: 29px;
  text-align: center;
}

.doMyDissertation button a,
.dissertationWritingService button a,
.capstoneProjects button a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.81);
}

/* Responsive Page Start Here  */

@media only screen and (max-width: 1199px) {
  .doMyDissertation .heading-1,
  .dissertationWritingService .heading-2,
  .capstoneProjects .heading-3 {
    font-size: 19px;
  }
  .doMyDissertation .paragraph,
  .dissertationWritingService .paragraph,
  .capstoneProjects .paragraph {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .doMyDissertation .heading-1, .dissertationWritingService .heading-2, .capstoneProjects .heading-3 {
    font-size: 25px;
}
  .dissertationWritingService {
    padding: 0 7%;
    left: 0%;
  }
  .doMyDissertation,
  .capstoneProjects {
    padding: 0 7%;
    right: 0%;
  }
  .image-section img {
    padding: 0 7%;
  }
  .image-section .dissertationWritingImage, .image-section .dissertationWritingServicesPicClass {
    height: 100%;
    left: 0;
}
}

@media only screen and (max-width: 900px) {
  .doMyDissertation .paragraph, .dissertationWritingService .paragraph, .capstoneProjects .paragraph {
    font-size: 16px;
    line-height: 35px;
}
}
@media only screen and (max-width: 767px) {
  .doMyDissertation .heading-1, .dissertationWritingService .heading-2, .capstoneProjects .heading-3 {
    font-size: 22px;
}
}
@media only screen and (max-width: 575px) {
  .thirdInnerSectionContentStartHere {
    --bs-gutter-x: 0rem !important;
  }
}
@media only screen and (max-width: 425px) {
  .doMyDissertation .heading-1, .dissertationWritingService .heading-2, .capstoneProjects .heading-3 {
    font-size: 20px;
}
}
