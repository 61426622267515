.blogTitleDateAndReadTime h1 {
    color: #006CA2;
    font-weight: 400;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    word-wrap: break-word;
}

.blogTitleDateAndReadTime p {
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #333333;
    text-align: center;
    font-size: 17px;
}


.blogPageInnerDivStartHere {
    background-color: #E3F1F7;
    padding: 2% 7%;
}

.formCommentSectionStartHere {
    margin-top: -41px;
}

.formCommentSectionInnerDiv {
    background-color: #E3F1F7;
    padding-right: 7%;
    padding-left: 7%;
    padding-top: 0%;
    padding-bottom: 2%;
}

.blogImageDiv img {
    width: 100% !important;
    height: auto !important;
    border-radius: 11px;
}

.blogPageContentFirst {
    padding: 5% 2%;
}

.blogPageContentSecond {
    padding: 0 2% 5% 2%;
}

.blogPageContentFirst p, .blogPageContentSecond p, .blogPageContentFirst ul li, .blogPageContentSecond ul li, .blogPageContentFirst ol li, .blogPageContentSecond ol li {
    font-weight: 300;
    font-size: 20px;
    line-height: 35px;
    text-align: justify;
    color: #333333;
    margin: 15px 0;
}

.blogPageContentFirst ul li .unorderedListItemHeading, .blogPageContentSecond ul li .unorderedListItemHeading {
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    text-align: left;
    color: #333333;
    margin: 15px 0;
    text-transform: none;
}

.blogPageContentFirst p a, .blogPageContentSecond p a, .blogPageContentSecond h2 a, .blogPageContentFirst h2 a {
    color: #3644BE;
    font-weight: 500;
}

.blogPageContentFirst span {
    color: #3644BE;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.blogPageContentFirst p b, .blogPageContentSecond p b {
    font-weight: 600;
}

.blogPageContentSecond h2, .blogPageContentFirst h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #006CA2;
    text-align: center;
    margin-bottom: 25px;
}

.blogPageContentSecond h2 span, .blogPageContentFirst h2 span {
    position: relative;
    top: -3px;
}

.blogPageContentSecond h2 span i, .blogPageContentFirst h2 span i {
    color: #006CA2;
}

.blogPageContentSecond h3, .blogPageContentFirst h3 {
    font-weight: 500;
    font-size: 21px;
    line-height: 41px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #006CA2;
    text-align: center;
    margin-bottom: 25px;
}

.boxEndDottedLineDivStartHere {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.boxEndDottedLineDivStartHere .boxEndDottedLine {
    border: 2px dashed #006CA2;
    width: 151px;
    margin-bottom: 25px;
}

.blogLastTitleAndLikeShareButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
    padding: 3% 0;
}

.blogLastTitleAndLikeShareButton p {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    text-align: justify;
    color: #333333;
}

.blogLastTitleAndLikeShareButton p span {
    font-size: 23px;
    margin: 0 31px;
    cursor: pointer;
}

.blogLastTitleAndLikeShareButton p .socialMediaLikeIcon {
    color: #E60D0D;
}

.blogLastTitleAndLikeShareButton p .socialMediaIcon {
    color: #006CA2;
}

.blogCommentFormSection {
    padding: 2% 25%;
}

.postCommentButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.postCommentButtonDiv .postCommentButton {
    margin-top: 11px;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    background: #3389B5;
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    outline: none;
    padding: 2.5% 6%;
    color: #FFFFFF;
}

.blogCommentFormSection .blogCommentForm .blogLabel {
    color: #006CA2;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
}

.blogCommentFormSection .blogCommentForm .blogLabel span {
    color: #E60D0D;
}

.blogCommentFormSection .blogCommentForm .formInput {
    border: 1px solid #006CA2;
    border-radius: 8px;
}

.blogAlsoReadSectionStartHere {
    padding: 3% 0;
}

.blogAlsoReadCard {
    background-color: #FFFFFF;
}

.blog-also-read-first-inner-div {
    height: 100%;
    background: #FFFFFF;
}

.blogAlsoReadHeading h2 {
    font-weight: 400;
    font-size: 35px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #006CA2;
}

.blogAlsoReadCardBody {
    /* border-top: none; */
    /* border-bottom: 1px solid #006CA2; */
    /* border-left: 1px solid #006CA2; */
    /* border-right: 1px solid #006CA2; */
    padding: 4%;
    /* box-shadow: 1px 0px 0.5px 0.5px #006CA2; */
}

.blogAlsoReadCardBody h5 {
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.03em;
    color: #006CA2;
    margin: 11px 0;
}

.blogAlsoReadCardBody p {
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
}

.blogAlsoReadCardBody .blogAlsoReadCardButton {
    background: #3389B5;
    border-radius: 4px;
    padding: 2.5% 6%;
    border: none;
    outline: none;
}

.blogAlsoReadCardBody .blogAlsoReadCardButton a {
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 500;
}

.blogResponsesHide {
    display: none;
}

.blogResponsesSectionStartHere {
    padding: 3% 0;
}

/* .blogCommentsResponsesHeading {
    display: block;
} */

/* .blogCommentsMainDiv {
    display: block;
} */

/* .commentsImageHeadingAndParaDiv {
    display: block;
} */

.blogResponsesHeading h3 {
    font-weight: 400;
    font-size: 35px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #006CA2;
}

.blogCommentsDiv {
    border-bottom: 2px solid #006CA2;
    padding: 0 5%;
}

.commentsImageHeadingAndParaDiv {
    display: flex;
    justify-content: left;
    display: none;
}

.commentReplyDiv {
    display: none;
}

.blogCommentsMainDiv {
    display: block;
}

.commentReplyDiv .replyTextareaInput {
    border: 1px solid #006CA2;
    border-radius: 8px;
}

.commentReplyDiv .postCommentReplyButton {
    background: #3389B5;
    border-radius: 4px;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    padding: 0.5% 5%;
}

.blogCommentIdentityImage img {
    position: relative;
    top: 0px;
    transform: translateY(50%);
    right: 15px;
}

.commentsImageHeadingAndParaDiv h4 {
    font-weight: 500;
    font-size: 23px;
    line-height: 40px;
    text-transform: capitalize;
    color: #006CA2;
    margin-top: 21px;
}

.commentsImageHeadingAndParaDiv p {
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-align: justify;
    color: #333333;
    text-align: justify;
}

.commentsImageHeadingAndParaDiv p a {
    color: #006CA2;
    text-decoration: none;
    font-weight: 600;
}

/* Copy To Clipboard Div Styling  */
.showCopiedContent {
    background: #006CA2;
    width: 375px;
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.showCopiedContent p {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: #FFFFFF;
    font-weight: 500;
    padding: 1% 0;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 767px) {
    .blogCommentFormSection {
        padding: 2% 7%;
    }
}
@media only screen and (max-width: 575px) {
    .blogTitleDateAndReadTime h1 {
        font-size: 35px;
    }
}

