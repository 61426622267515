

.literatureReviewBannerImageSection .literatureReviewInnerDivOfBannerImage {
    background-image: url('../Image/Dissertation_Literature_Review/Banner_Image.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    width: 100%;
    height: 475px;
}

.literatureReviewFirstTwoQuestionSection {
    margin: 5% 5% 2% 5%;
    padding: 0 0%;
}

.literatureReviewFirstQuestion h2, .literatureReviewSecondQuestion h2, .dissertationLiteratureReviewLastThreeQuestions h2, .dissertationLiteratureReviewImagesAndChaptersSection h2, .literatureReviewFrequentlyAskedQuestions h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 46px;
    letter-spacing: 0.1em;
    color: #006CA2;
}

.literatureReviewFirstQuestion p, .literatureReviewSecondQuestion p, .dissertationLiteratureReviewLastThreeQuestions p {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 29px;
    color: #006CA2;
    text-align: justify;
}

.dissertationLiteratureReviewLastThreeQuestions h2 {
    margin-top: 4%;
}

.dissertationLiteratureReviewImagesAndChaptersSection h2 {
    font-size: 17px;
    margin-top: 5%;
}

.dissertationLiteratureReviewLastThreeQuestions button {
    background: linear-gradient(180deg, #095B84 0%, #37A3D9 40.1%, #1F5E7D 73.44%, #1F5E7D 100%);
    border-radius: 4px;
    outline: none;
    border: none;
    padding: 1% 5%;
}

.dissertationLiteratureReviewLastThreeQuestions button a {
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 17px;
    text-decoration: none;
}

.dissertationLiteratureReviewImagesAndChaptersSection .firstBackgroundImageOfThisSection {
    background-image: url('../Image/Dissertation_Literature_Review/Language_Expert.png');
    padding-top: 21%;
}

.dissertationLiteratureReviewImagesAndChaptersSection .firstBackgroundImageOfThisSection, .dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection, .literatureReviewLeftSideImageUnderFaqSection .backgroundImageUnderFaqSection {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 431px;
    height: 342px;
    font-style: italic;
    font-weight: 600;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
}

.dissertationLiteratureReviewImagesAndChaptersSection ul {
    background: #FFFFFF;
    border-top: 5px solid #3389B5;
    border-left: 1px solid #3389B5;
    border-bottom: 1px solid #3389B5;
    border-right: 1px solid #3389B5;
    border-radius: 10px 10px;
    padding: 0 7%;
    width: 375px;
}

.dissertationLiteratureReviewImagesAndChaptersSection ul li {
    list-style: none;
    margin: 2% 1%;
    padding: 2% 0;
}

.dissertationLiteratureReviewImagesAndChaptersSection ul li img {
    width: 15px;
}

.dissertationLiteratureReviewImagesAndChaptersSection ul li a {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    text-decoration-line: underline;
    color: #3389B5;
}

.dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection {
    background-image: url('../Image/Dissertation_Literature_Review/Own_Research.png');
    padding-top: 45%;
}

.guaranteesHeading h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 58px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #006CA2;
    margin: 1% 0 5% 0;
}

.dissertationLiteratureReviewCardSection {
    background: #FFFFFF;
    border: 1px solid rgba(0, 108, 162, 0.1);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
    padding: 3% 4% 7% 4%;
}

.firstCard .cardNumberOne {
    background-image: url('../Image/Dissertation_Literature_Review/1.png');
}

.secondCard .cardNumberTwo {
    background-image: url('../Image/Dissertation_Literature_Review/2.png');
}

.thirdCard .cardNumberThree {
    background-image: url('../Image/Dissertation_Literature_Review/3.png');
}

.fourthCard .cardNumberFour {
    background-image: url('../Image/Dissertation_Literature_Review/4.png');
}

.fifthCard .cardNumberFive {
    background-image: url('../Image/Dissertation_Literature_Review/5.png');
}

.firstCard, .secondCard, .thirdCard, .fourthCard, .fifthCard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.firstCard .cardNumberOne, .secondCard .cardNumberTwo, .thirdCard .cardNumberThree, .fourthCard .cardNumberFour, .fifthCard .cardNumberFive {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;
    position: relative;
}

.guaranteesUnderLiteratureReviewPage .guaranteesSectionCard {
    width: 18%;
}

.literatureReviewCard p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    text-align: center;
    text-decoration-line: underline;
    color: #006CA2;
    margin-top: -13%;
}

/* FAQ Section Start Here  */

.accordion {
    --bs-accordion-btn-padding-x: 0.25rem !important;
    --bs-accordion-btn-padding-y: 1rem !important;
}

.accordion-item h2 button {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #3389B5;
}

.accordion-item .accordion-body {
    font-size: 17px;
    color: #3389B5;
    line-height: 31px;
}

.literatureReviewFrequentlyAskedQuestions .literatureReviewReadMoreOnFaqButton {
    margin: 1% 0;
}

.literatureReviewFrequentlyAskedQuestions .literatureReviewReadMoreOnFaqButton span {
    border-bottom: 2px solid #FFE02B;
    color: #006CA2;
}

.literatureReviewFrequentlyAskedQuestions .literatureReviewReadMoreOnFaqButton a {
    text-decoration: none;
    color: #006CA2;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    letter-spacing: 0.02em;
}

.frequentlyAskedQuestionsUnderLiteratureReviewPage {
    margin: 7% 0;
}

.literatureReviewLeftSideImageUnderFaqSection .backgroundImageUnderFaqSection {
    background-image: url('../Image/Dissertation_Literature_Review/Contact_Expert.png');
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive Page Start Here */

@media only screen and (max-width: 1199px) {
    .guaranteesUnderLiteratureReviewPage .guaranteesSectionCard {
        width: 20%;
    }
}
@media only screen and (max-width: 1100px) {
    .dissertationLiteratureReviewImagesAndChaptersSection .firstBackgroundImageOfThisSection, .dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection, .literatureReviewLeftSideImageUnderFaqSection .backgroundImageUnderFaqSection {
        position: relative;
        right: 9%;
    }
}
@media only screen and (max-width: 991px) {
    .literatureReviewFirstTwoQuestionSection {
        margin: 5% 5% 0% 5%;
    }
    .guaranteesUnderLiteratureReviewPage .guaranteesSectionCard {
        width: 29%;
    }
    .dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection {
        padding: 0%;
    }
    .dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection, .literatureReviewLeftSideImageUnderFaqSection {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dissertationLiteratureReviewLastThreeQuestions button {
        margin-bottom: 5% !important;
    }
    .dissertationLiteratureReviewImagesAndChaptersSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .literatureReviewFrequentlyAskedQuestions .literatureReviewReadMoreOnFaqButton {
        margin: 1% 0 5% 0;
    }
    .dissertationLiteratureReviewImagesAndChaptersSection ul {
        width: 401px;
    }
    .dissertationLiteratureReviewImagesAndChaptersSection .firstBackgroundImageOfThisSection, .dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection, .literatureReviewLeftSideImageUnderFaqSection .backgroundImageUnderFaqSection {
        right: 0%;
    }
}
@media only screen and (max-width: 807px) {
    .literatureReviewBannerImageSection .literatureReviewInnerDivOfBannerImage {
        height: 411px;
    }
}
@media only screen and (max-width: 767px) {
    .guaranteesUnderLiteratureReviewPage .guaranteesSectionCard {
        width: 40%;
    }
}
@media only screen and (max-width: 600px) {
    .literatureReviewBannerImageSection .literatureReviewInnerDivOfBannerImage {
        height: 349px;
    }
}
@media only screen and (max-width: 500px) {
    .dissertationLiteratureReviewLastThreeQuestionAndChaptersSection {
        padding: 0 3%;
    }
}
@media only screen and (max-width: 453px) {
    .literatureReviewFirstQuestion h2, .literatureReviewSecondQuestion h2, .dissertationLiteratureReviewLastThreeQuestions h2, .dissertationLiteratureReviewImagesAndChaptersSection h2, .literatureReviewFrequentlyAskedQuestions h2 {
        font-size: 23px;
    }
    .literatureReviewFirstQuestion p, .literatureReviewSecondQuestion p, .dissertationLiteratureReviewLastThreeQuestions p {
        font-size: 15px;
    }
    .guaranteesHeading h2 {
        font-size: 31px;
    }
    .dissertationLiteratureReviewImagesAndChaptersSection .firstBackgroundImageOfThisSection, .dissertationLiteratureReviewImagesAndChaptersSection .secondBackgroundImageOfThisSection, .literatureReviewLeftSideImageUnderFaqSection .backgroundImageUnderFaqSection {
        width: 100%;
        font-size: 27px;
    }
    .guaranteesUnderLiteratureReviewPage .guaranteesSectionCard {
        width: 48%;
    }
}
@media only screen and (max-width: 425px) {
    .literatureReviewBannerImageSection .literatureReviewInnerDivOfBannerImage {
        height: 301px;
    }
    .guaranteesHeading h2 {
        font-size: 28px;
    }
    .firstCard .cardNumberOne, .secondCard .cardNumberTwo, .thirdCard .cardNumberThree, .fourthCard .cardNumberFour, .fifthCard .cardNumberFive {
        width: 150px;
        height: 150px;
    }
    .literatureReviewCard p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 375px) {
    .dissertationLiteratureReviewImagesAndChaptersSection ul {
        width: 301px;
    }
    .guaranteesUnderLiteratureReviewPage .guaranteesSectionCard {
        width: 53%;
    }
}


/* CSS of Banner Form Start Here */

.dissertationLiteratureReviewQuickQueryForm {
    width: 685px;
    height: auto;
    background: linear-gradient(112.98deg, #FFFFFF 0%, rgba(255, 255, 255, 0.2) 100%);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1.5%;
    position: relative;
    /* left: 3%; */
    top: 311px;
    margin: 0 auto;
  }
  
  .dissertationLiteratureReviewQuickQueryForm .literatureReviewEmail, .dissertationLiteratureReviewQuickQueryForm .dissertationLiteratureReviewFormSelect {
    border: 2px solid #006CA2;
    border-radius: 4px;
    outline: none !important;
    width: 209px;
    padding: 2px;
    background: transparent;
  }
  
  .dissertationLiteratureReviewFormSelect {
    color: #006CA2 !important;
    font-weight: 500;
  }
  
    .dissertationLiteratureReviewQuickQueryForm .literatureReviewEmail::placeholder {
    color: rgba(0, 108, 162, 0.49);
    font-weight: 500;
    line-height: 23px;
  }
  
  .dissertationLiteratureReviewQuickQueryForm .dissertationLiteratureReviewFormSelect {
    padding: 2px 0;
  }
  
  .dissertationLiteratureReviewQuickQueryForm .dissertationLiteratureReviewFormSelect option {
    color: #006CA2;
    font-weight: 500;
  }
  
  .dissertationLiteratureReviewSubmitButtonDiv {
    position: relative;
    top: 21px;
    display: flex;
    justify-content: center;
  }
  
  .dissertationLiteratureReviewSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    background-color: #006CA2;
    color: #FFFFFF;
    border: none;
    outline: none;
    padding: 1% 2%;
    border-radius: 4px;
    margin-bottom: 4%;
  }
  
  .displayLiteratureReview {
    display: none;
  }

  /* Responsive Page Start Here */
@media only screen and (max-width: 1340px) {
    .literatureReviewBannerImageSection .literatureReviewInnerDivOfBannerImage {
        height: 407px;
    }
    .dissertationLiteratureReviewQuickQueryForm {
        top: 255px;
    }
  }
@media only screen and (max-width: 1300px) {
    .dissertationLiteratureReviewQuickQueryForm {
        top: 229px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dissertationLiteratureReviewQuickQueryForm {
        top: 253px;
    }
  }
  @media only screen and (max-width: 991px) {
    .dissertationLiteratureReviewQuickQueryForm {
        top: 263px;
    }
  }
  @media only screen and (max-width: 767px) {
    .dissertationLiteratureReviewQuickQueryForm {
      width: 525px;
      top: 173px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .literatureReviewEmail.ms-2 {
      margin-left: 0rem !important;
    }
    .dissertationLiteratureReviewQuickQueryForm .literatureReviewEmail, .dissertationLiteratureReviewQuickQueryForm .dissertationLiteratureReviewFormSelect {
      width: 90%;
      margin: 6px 0;
  }
  .dissertationLiteratureReviewSubmitButtonDiv {
    top: 14px;
  }
  .dissertationLiteratureReviewSubmitButtonDiv .dissertationWritingServicesEnquireNowButton {
    font-size: 16px;
    line-height: 23px;
    padding: 3% 0%;
    margin-bottom: 17%;
    width: 160px;
  }
  .dissertationLiteratureReviewSubmitButtonDiv {
  margin-top: 5px;
  }
  }
  @media only screen and (max-width: 600px) {
    .dissertationLiteratureReviewQuickQueryForm {
        top: 175px;
    }
  }
  @media only screen and (max-width: 575px) {
    .dissertationLiteratureReviewBannerImageHeadingAndForm {
        padding: 0% 7%;
    }
    .dissertationLiteratureReviewQuickQueryForm {
      left: 0%;
      width: 100%;
  }
  }